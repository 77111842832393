import axios from "axios";
import config from "../config/config";

axios.defaults.withCredentials = true;

class APIKit {
  instance = axios.create({
    baseURL: config.apiURL,
    timeout: 10000,
  });
  create({ headers }) {
    this.instance = axios.create({
      baseURL: config.apiURL,
      timeout: 10000,
      headers: {
        ...headers,
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    });

    return this.instance;
  }

  getInstance() {
    return this.instance;
  }
}

export default new APIKit();
