import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";

import MyCategories from "./feed/MyCategories";
import TopCategories from "./feed/TopCategories";

import CategoryQuestions from "../components/CategoryQuestions";
import Loader from "../components/Loader";
import InformationModal from "../components/InformationModal";
import config from "../config/config";
import { commonApi } from "../services";
import { useCategories } from "../providers";

const UNFOLLOW = 1;
const FOLLOW = 2;

const getImage = (category) =>
  category.image
    ? `${config.apiURL}/images/categories/${category.image}`
    : `${config.apiURL}/images/categories/category-placeholder-4.png`;

const Category = ({ match }) => {
  const { id } = match.params;
  const { categoriesState, dispatch } = useCategories();

  const [isLoading, setIsLoading] = useState(false);

  const [followLoading, setFollowLoading] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const categories = categoriesState?.categories;
  const category = categoriesState?.byId[id];
  const isFollowing = categoriesState?.followed.indexOf(parseInt(id)) > -1;

  useEffect(() => {
    if (!categories?.length) {
      setIsLoading(true);
      commonApi.getCategories().then((categories) => {
        setIsLoading(false);
        dispatch({
          type: "getCategoriesSuccess",
          payload: categories,
        });
      });
    }
  }, [dispatch, categories]);

  const onFollow = (id, action) => {
    const oldFollowed = categoriesState?.followed.slice();
    const followed = categoriesState?.followed;
    if (action === UNFOLLOW) {
      followed.splice(followed.indexOf(id), 1);
    } else {
      followed.push(id);
    }
    dispatch({
      type: "followCategoriesSuccess",
      payload: { followed: followed, oldFollowed: oldFollowed },
    });
  };

  const toggleFollow = () => {
    setFollowLoading(true);
    if (isFollowing) {
      if (categoriesState?.followed.length === 1) {
        setFollowLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Dejar de seguir",
          body: "Debes seguir al menos un tema de interés.",
        });
      }
      commonApi
        .unfollowCategory(id)
        .then(() => {
          setFollowLoading(false);
          onFollow(parseInt(id), UNFOLLOW);
        })
        .catch((err) => {
          setFollowLoading(false);
          onFollow(parseInt(id), FOLLOW);
          return setInformationModal({
            isOpen: true,
            title: "Dejar de seguir",
            body: err?.response?.data?.message,
          });
        });
    } else {
      commonApi
        .followCategories([id])
        .then(() => {
          setFollowLoading(false);
          onFollow(parseInt(id), FOLLOW);
        })
        .catch((err) => {
          setFollowLoading(false);
          onFollow(parseInt(id), UNFOLLOW);
          return setInformationModal({
            isOpen: true,
            title: "Seguir tema",
            body: err?.response?.data?.message,
          });
        });
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="min-width-50 my-2">
          <Loader size="sm" />
        </div>
      ) : category ? (
        <Row className="mt-3">
          <Col lg={3} md={12} className="order-md-1 order-lg-1">
            <MyCategories />
          </Col>
          <Col lg={6} md={12} className="order-md-1 order-lg-1">
            <Card className="border">
              <CardBody className="p-0 d-flex flex-column">
                <div className="p-3 d-flex align-items-center justify-content-between flex-wrap col-12">
                  <div className="d-flex align-items-center col-12 col-md-8">
                    <div
                      className="rounded border mr-3 flex-shrink-0"
                      style={{
                        backgroundPosition: "center",
                        backgroundImage: `url(${getImage(category)})`,
                        width: "96px",
                        height: "48px",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        cursor: "pointer",
                        alignSelf: "center",
                      }}
                    ></div>
                    <div className="d-flex flex-column">
                      <h4 className="m-0">{category?.name || ""}</h4>
                      <div className="d-flex flex-column text-muted">
                        <small>{category?.description}</small>
                        <small>
                          {category?.totalQuestions} preguntas,{" "}
                          {category?.totalFollowers}{" "}
                          {category?.totalFollowers === 1
                            ? "seguidor"
                            : "seguidores"}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="ml-1 col-12 d-block d-md-none mt-3">
                    {followLoading ? (
                      <div className="min-width-50">
                        <Loader size="sm" />
                      </div>
                    ) : isFollowing ? (
                      <Button
                        onClick={toggleFollow}
                        size="sm"
                        className="col-12 btn btn-danger cursor-pointer"
                      >
                        Dejar de Seguir
                      </Button>
                    ) : (
                      <Button
                        onClick={toggleFollow}
                        size="sm"
                        className="col-12 btn btn-success cursor-pointer"
                      >
                        Seguir
                      </Button>
                    )}
                  </div>
                  <div className="ml-1 d-none d-md-block">
                    {followLoading ? (
                      <div className="min-width-50">
                        <Loader size="sm" />
                      </div>
                    ) : isFollowing ? (
                      <Button
                        onClick={toggleFollow}
                        size="sm"
                        className="btn btn-danger cursor-pointer"
                      >
                        Dejar de Seguir
                      </Button>
                    ) : (
                      <Button
                        onClick={toggleFollow}
                        size="sm"
                        className="btn btn-success cursor-pointer"
                      >
                        Seguir
                      </Button>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
            <CategoryQuestions key={id} categoryId={id} />
          </Col>
          <Col lg={3} md={12} className="order-md-1 order-lg-1">
            <TopCategories />
          </Col>
        </Row>
      ) : null}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </React.Fragment>
  );
};

export default Category;
