import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { useHistory } from "react-router-dom";

import CategoryItem from "../../components/CategoryItem";
import { useCategories } from "../../providers";

const MAX_ITEMS = 10;

const TopCategories = () => {
  const history = useHistory();
  const { categoriesState } = useCategories();

  const categories = categoriesState?.categories;

  const topCategories = categories
    .filter((category) => categoriesState?.followed.indexOf(category.id) === -1)
    .sort((x, y) => y.popularity - x.popularity);

  return (
    <React.Fragment>
      <Card className="categories-card d-none d-lg-block">
        <CardHeader className="app-search">
          <h4 className="text-center">Otros temas para seguir</h4>
        </CardHeader>
        <CardBody className="py-2 px-0 mt-n2">
          <div className="inbox-widget">
            {topCategories.map((category, index) => (
              <CategoryItem
                key={category.id}
                category={category}
                index={index}
              />
            ))}
            {topCategories.length > MAX_ITEMS ? (
              <div className="inbox-item d-flex align-items-center justify-content-between">
                <div
                  onClick={() => history.push(`/categories`)}
                  className="cursor-pointer flex-grow-1 d-flex align-items-center justify-content-start pl-2 pr-0 pb-0"
                >
                  <p className="col-12 text-center mb-0 inbox-item-author mb-n2 text-primary font-weight-500">
                    Ver Todos
                  </p>
                </div>
              </div>
            ) : null}
            {!topCategories?.length ? (
              <div className="text-center">
                Ya estás siguiendo todos los temas
              </div>
            ) : null}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default TopCategories;
