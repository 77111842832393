import APIKit from "./ApiKit";
import { utils } from "../utils/utils";

export const commonApi = {
  getCategories: (data = {}) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return APIKit.getInstance()(`/api/categories?${query}`, {
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  getCateogry: (id) => {
    return APIKit.getInstance()(`/api/categories/${id}`, {
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  followCategories: (data) => {
    return APIKit.getInstance()(`/api/follow-categories`, {
      method: "POST",
      data: {
        categoryIds: data,
      },
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  unfollowCategory: (id) => {
    return APIKit.getInstance()(`/api/follow-categories/${id}`, {
      method: "DELETE",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  getQuestions: (data = {}, withPageData = false) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return APIKit.getInstance()(`/api/questions?${query}`, {
      method: "get",
    })
      .then((result) =>
        withPageData ? result.data : result.data?.questions || []
      )
      .catch((err) => utils.handleError(err));
  },
  getQuestion: (questionId) => {
    return APIKit.getInstance()(`/api/questions/${questionId}`, {
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  saveQuestion: (data) => {
    return APIKit.getInstance()(`/api/questions`, {
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  updateQuestion: (id, data) => {
    return APIKit.getInstance()(`/api/questions/${id}`, {
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  deleteQuestion: (id) => {
    return APIKit.getInstance()(`/api/questions/${id}`, {
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  getFeed: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return APIKit.getInstance()(`/api/users/feed?${query}`, {
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  getAnswer: (questionId, id) => {
    return APIKit.getInstance()(`/api/questions/${questionId}/answers/${id}`, {
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  saveAnswer: (questionId, data) => {
    return APIKit.getInstance()(`/api/questions/${questionId}/answers`, {
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  updateAnswer: (questionId, answerId, data) => {
    return APIKit.getInstance()(
      `/api/questions/${questionId}/answers/${answerId}`,
      {
        method: "put",
        data,
      }
    )
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  voteAnswer: (questionId, answerId, data) => {
    return APIKit.getInstance()(
      `/api/questions/${questionId}/answers/${answerId}/votes`,
      {
        method: "post",
        data,
      }
    )
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  updateVoteAnswer: (questionId, answerId, voteId, data) => {
    return APIKit.getInstance()(
      `/api/questions/${questionId}/answers/${answerId}/votes/${voteId}`,
      {
        method: "put",
        data,
      }
    )
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  unVoteAnswer: (questionId, answerId, voteId) => {
    return APIKit.getInstance()(
      `/api/questions/${questionId}/answers/${answerId}/votes/${voteId}`,
      {
        method: "delete",
      }
    )
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  commentAnswer: (questionId, answerId, data) => {
    return APIKit.getInstance()(
      `/api/questions/${questionId}/answers/${answerId}/comments`,
      {
        method: "post",
        data,
      }
    )
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  updateComment: (questionId, answerId, commentId, data) => {
    return APIKit.getInstance()(
      `/api/questions/${questionId}/answers/${answerId}/comments/${commentId}`,
      {
        method: "put",
        data,
      }
    )
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  deleteComment: (questionId, answerId, id) => {
    return APIKit.getInstance()(
      `/api/questions/${questionId}/answers/${answerId}/comments/${id}`,
      {
        method: "delete",
      }
    )
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  deleteAnswer: (questionId, id) => {
    return APIKit.getInstance()(`/api/questions/${questionId}/answers/${id}`, {
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  getUserAnswers: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    const url = `/api/answers?${query}`;
    return APIKit.getInstance()(url, {
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  getUserComments: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    const url = `/api/comments?${query}`;
    return APIKit.getInstance()(url, {
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  getUserQuestions: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    const url = `/api/questions?${query}`;
    return APIKit.getInstance()(url, {
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  getUserInfo: (id) => {
    return APIKit.getInstance()(`/api/users/${id}`, {
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  getUsers: (data = {}) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return APIKit.getInstance()(`/api/users?${query}`, {
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  createUser: (data) => {
    return APIKit.getInstance()(`/api/users/`, {
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  updateUser: (id, data) => {
    return APIKit.getInstance()(`/api/users/${id}`, {
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  getCategoryQuestions: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    const url = `/api/questions?${query}`;
    return APIKit.getInstance()(url, {
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  followUser: (id) => {
    return APIKit.getInstance()(`/api/follow-users/${id}`, {
      method: "POST",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  unfollowUser: (id) => {
    return APIKit.getInstance()(`/api/follow-users/${id}`, {
      method: "DELETE",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  getUserNotifications: (userId, data = {}) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&");
    return APIKit.getInstance()(`/api/notifications/${userId}?${query}`, {
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  readNotification: (id) => {
    return APIKit.getInstance()(`/api/notifications/read/${id}`, {
      method: "POST",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  readAllNotification: () => {
    return APIKit.getInstance()(`/api/notifications/read/all`, {
      method: "POST",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  unreadNotification: (id) => {
    return APIKit.getInstance()(`/api/notifications/unread/${id}`, {
      method: "POST",
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  reportContent: (data) => {
    return APIKit.getInstance()(`/api/content-report`, {
      method: "POST",
      data,
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  reportUser: (reporterId, id, reason) => {
    const data = { reporterId, userId: id, reason };
    return APIKit.getInstance()(`/api/content-report`, {
      method: "POST",
      data,
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  captchaVerify: (token) => {
    return APIKit.getInstance()(`/api/auth/captcha-verify`, {
      method: "POST",
      data: { token },
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
};
