// @flow
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { useHistory } from "react-router-dom";

import avatar from "../../assets/images/placeholder-3.png";
import { authApi } from "../../services";
import {
  useAuth,
  useCategories,
  useNotifications,
  useProfile,
} from "../../providers";

const ProfileDropdown = () => {
  const history = useHistory();
  const [authContext, setAuthContext] = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { dispatch: dispatchCategoriesChange } = useCategories();
  const { dispatchNotificationsChange } = useNotifications();
  const { dispatch: dispatchProfileChange } = useProfile();

  const logout = () => {
    authApi.logout();
    setAuthContext({ currentUser: null });
    history.push("/auth/sign-in");
    dispatchCategoriesChange({
      type: "resetStatus",
    });
    dispatchNotificationsChange({
      type: "resetStatus",
    });
    dispatchProfileChange({
      type: "resetStatus",
    });
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen(!dropdownOpen)}
    >
      <DropdownToggle
        data-toggle="dropdown"
        tag="button"
        className="nav-link dropdown-toggle nav-user arrow-none mx-0"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="account-user-avatar">
          <div
            className="mr-2 rounded-circle"
            style={{
              backgroundPosition: "center",
              backgroundImage: `url(${
                authContext.currentUser.image || avatar
              })`,
              width: "32px",
              height: "32px",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              cursor: "pointer",
              alignSelf: "center",
            }}
          ></div>
        </span>
        <span className="text-dark">
          <span className="account-user-name">
            {authContext.currentUser.firstName}
          </span>
        </span>
      </DropdownToggle>
      <DropdownMenu
        right
        className="dropdown-menu-animated topbar-dropdown-menu profile-dropdown mt-1"
      >
        <div onClick={() => setDropdownOpen(!dropdownOpen)}>
          <Link
            to="#"
            onClick={() => history.push(`/users/${authContext.currentUser.id}`)}
            className="dropdown-item notify-item"
          >
            <i className="uil uil-user mr-1"></i>
            <span>Mi Perfil</span>
          </Link>
          <Link to="#" onClick={logout} className="dropdown-item notify-item">
            <i className="uil uil-sign-out-alt mr-1"></i>
            <span>Salir</span>
          </Link>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
