import React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Badge,
} from "reactstrap";
import { useHistory } from "react-router";
import Pagination from "react-js-pagination";
import { useNotifications } from "../providers";

const NOTIFICATION_STATUS_UNREAD = 1;

const NotificationsModal = ({ onClose }) => {
  const { notificationsState, dispatchNotificationsChange } =
    useNotifications();
  const history = useHistory();

  return (
    <Modal isOpen={true} onClosed={onClose} toggle={onClose}>
      <ModalHeader>Notificaciones</ModalHeader>
      <ModalBody>
        <ListGroup flush className="text-left border rounded">
          <ListGroupItem
            className="pb-2 border-bottom font-weight-bold text-body bg-light text-center"
            tag="div"
          >
            Notificaciones
          </ListGroupItem>
          {notificationsState?.notifications
            .sort((x, y) => x.id - y.id)
            .slice(
              (notificationsState?.page - 1) * notificationsState?.sizePerPage,
              notificationsState?.sizePerPage * notificationsState?.page
            )
            .map((notification) => (
              <ListGroupItem
                onClick={() => {
                  history.push(`/questions/${notification.payload.questionId}`);
                  onClose();
                }}
                key={notification.id}
                className="border-bottom d-flex justify-content-between align-items-center cursor-pointer"
                tag="div"
              >
                <div className="d-flex flex-column">
                  <b>{notification.title}</b>
                  <small>
                    <div
                      className="text-italic"
                      dangerouslySetInnerHTML={{ __html: notification.body }}
                    ></div>
                  </small>
                </div>
                <Badge
                  color={
                    notification.status === NOTIFICATION_STATUS_UNREAD
                      ? "success"
                      : "warning"
                  }
                  size={18}
                  pill
                >
                  {notification.status === NOTIFICATION_STATUS_UNREAD
                    ? "Sin leer"
                    : "Leida"}
                </Badge>
              </ListGroupItem>
            ))}
          {!notificationsState?.notifications.length ? (
            <ListGroupItem
              className="border-bottom d-flex justify-content-between align-items-center"
              tag="div"
            >
              No hay notificaciones para mostar
            </ListGroupItem>
          ) : null}
        </ListGroup>
        {notificationsState?.notifications.length >
        notificationsState?.sizePerPage ? (
          <div className="mt-4 d-flex justify-content-center">
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={notificationsState?.page}
              itemsCountPerPage={notificationsState?.sizePerPage}
              totalItemsCount={notificationsState?.notifications.length || 0}
              pageRangeDisplayed={5}
              onChange={(page) =>
                dispatchNotificationsChange({
                  type: "onPageChange",
                  payload: { page },
                })
              }
            />
          </div>
        ) : null}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"primary"} onClick={onClose}>
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default NotificationsModal;
