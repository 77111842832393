import React from "react";

import Question from "../../pages/feed/Question";

import { Card, CardBody } from "reactstrap";

import Loader from "../Loader";

import InfiniteScroll from "react-infinite-scroll-component";
import { usePaginatedResource } from "../../utils/hooks";

const UserQuestions = ({ questionsData, userId }) => {
  const [isLoading, fetchMore] = usePaginatedResource({
    userId,
    type: "Question",
    pageSize: 5,
  });
  const questions = questionsData.data || [];

  return (
    <React.Fragment>
      <InfiniteScroll
        className="results-list"
        dataLength={questions.length} //This is important field to render the next data
        next={fetchMore}
        hasMore={questionsData.page < questionsData.totalPages}
        loader={<h4 className="mb-3 text-center">Cargando..</h4>}
        endMessage={
          <Card className="mb-0 border">
            <CardBody className="text-center">
              <span>No hay más preguntas para mostrar</span>
            </CardBody>
          </Card>
        }
        scrollableTarget="wrapper"
      >
        {questions.length
          ? questions.map((question) => (
              <Question
                readOnly={true}
                defaultExpanded={false}
                question={question}
                key={`${question.id}-${question.updatedAt}`}
              />
            ))
          : null}
      </InfiniteScroll>
      {isLoading ? (
        <div className="min-width-50 my-2">
          <Loader size="sm" />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default UserQuestions;
