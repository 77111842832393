import APIKit from "./ApiKit";

export const authApi = {
  login: (data) => {
    return APIKit.getInstance()(`/api/auth/w/sign-in`, {
      method: "post",
      data: {
        username: data.email,
        password: data.password,
      },
    })
      .then((result) => {
        APIKit.create({
          headers: {
            Authorization: `Bearer ${result.data.token}`,
          },
        }).interceptors.request.use((config) => {
          return {
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Bearer ${result.data.token}`,
            },
          };
        });
        return Promise.resolve(result);
      })
      .then((result) => result.data);
  },
  logout: () => {
    return APIKit.getInstance()(`/api/auth/logout`, {
      method: "post",
    }).then((result) => result.data);
  },
  reset: (data) => {
    return APIKit.getInstance()(`/api/auth/reset-password`, {
      method: "post",
      data,
    }).then((result) => result.data);
  },
  resetNewPassword: (data) => {
    return APIKit.getInstance()(`/api/auth/reset-password`, {
      method: "put",
      data: data,
    }).then((result) => result.data);
  },
  validate: () => {
    return APIKit.getInstance()(`/api/auth/w/validate`, {
      method: "get",
    }).then((result) => result.data);
  },
};
