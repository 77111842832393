// @flow
import React, { useState } from "react";

import { Button } from "reactstrap";
import QuestionModal from "../customer/QuestionModal";

import NotificationDropdown from "./NotificationDropdown";
import ProfileDropdown from "./ProfileDropdown";
import TopbarSearch from "./TopbarSearch";
import { useHistory } from "react-router-dom";

import logo from "../../assets/images/logo_horiz.png";
import logoSm from "../../assets/images/logo-gaucho-sm.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTag } from "@fortawesome/free-solid-svg-icons";
import { useQuestions } from "../../providers";
import { commonApi } from "../../services";

const Topbar = ({ navCssClasses }) => {
  const [newQuestionModal, setNewQuestionModal] = useState(false);
  const { dispatchQuestionsChange } = useQuestions();
  const history = useHistory();

  const onLogoClick = () => {
    dispatchQuestionsChange({
      type: "refreshQuestions",
    });
  };

  return (
    <React.Fragment>
      <div className={`navbar-custom ${navCssClasses}`}>
        <div className="navbar-container d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center flex-grow-1 px-0">
            <a onClick={onLogoClick} href="/">
              <span className="cursor-pointer d-md-none d-lg-block topnav-logo text-center font-weight-bold px-3 flex-shrink-0 px-0">
                <img
                  className="max-height-50 px-0"
                  src={logo}
                  alt={"Red Gaucho"}
                ></img>
              </span>
              <span className="cursor-pointer d-none d-md-block d-lg-none topnav-logo text-center font-weight-bold px-3 flex-shrink-0 px-0">
                <img
                  className="max-width-50 px-0"
                  src={logoSm}
                  alt={"Red Gaucho"}
                ></img>
              </span>
            </a>
            <div
              className="d-none d-md-block app-search mr-2 flex-grow-1"
              style={{ maxWidth: 500 }}
            >
              <TopbarSearch />
            </div>
          </div>
          <ul className="justify-content-end px-0 list-unstyled topbar-right-menu float-right mb-0 d-flex align-items-center">
            <li
              onClick={() => history.push("/categories")}
              className="border-left notification-list px-2 py-3 d-flex align-items-center cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faTag}
                size={"lg"}
                className="text-muted"
              />
            </li>
            <li className="border-left notification-list px-2">
              <NotificationDropdown />
            </li>
            <li className="notification-list">
              <ProfileDropdown />
            </li>
            <li>
              <Button
                className="mx-2 pt-1"
                color="primary"
                onClick={() => setNewQuestionModal(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="d-lg-none" />
                <span className="d-none d-lg-block">Añadir Pregunta</span>
              </Button>
            </li>
          </ul>
        </div>
        <div
          style={{ boxShadow: "0 3px 6px rgb(0 0 0 / 4%)" }}
          className="d-md-none d-block app-search bg-white border-top border-bottom col-12 px-0 mb-3"
        >
          <TopbarSearch />
        </div>
      </div>
      {newQuestionModal ? (
        <QuestionModal
          onSubmit={({ title, content, categoriesIds, images }) => {
            setNewQuestionModal(false);
            commonApi
              .saveQuestion({
                title,
                content,
                categoriesIds,
                images,
              })
              .then(() =>
                dispatchQuestionsChange({
                  type: "refreshQuestions",
                })
              );
          }}
          onClose={() => setNewQuestionModal(false)}
          question={null}
        />
      ) : null}
    </React.Fragment>
  );
};

export default Topbar;
