import React, { useState } from "react";

import { Media, Button, Input } from "reactstrap";

import avatar from "../../assets/images/placeholder-3.png";

import moment from "moment";

import ReactQuill from "react-quill";

import Loader from "../../components/Loader";
import InformationModal from "../../components/InformationModal";
import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { utils } from "../../utils/utils";
import { utils as awsUtils } from "../../utils/aws";
import { useAuth } from "../../providers";

moment.locale("es-es");

const MAX_CHAR_ANSWER = 10000;
const IMAGE_TYPE_CONTENT = "IMAGE_TYPE_CONTENT";
const MODE_QUESTION_ANSWER = 1;

const UserAnswer = ({
  onSubmit,
  mode,
  id = null,
  value,
  images: defaultImages,
  parent,
  editing = false,
}) => {
  const [authContext] = useAuth();
  const [answering, setAnswering] = useState(editing);
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState(defaultImages || []);
  const [content, setContent] = useState(value || "");

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const srcToFile = async (src, fileName, mimeType) =>
    fetch(src)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], fileName, { type: mimeType }));

  const getImages = async (images) => {
    const files = await Promise.all(
      images.map((image, i) =>
        srcToFile(image, `questionImage-${i}`, "image/png")
      )
    );
    return files;
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          ["bold", "italic", "underline"], // toggled buttons
          mode === MODE_QUESTION_ANSWER
            ? [{ list: "ordered" }, { list: "bullet" }]
            : [],
          mode === MODE_QUESTION_ANSWER ? ["image"] : [],
        ],
        handlers: {
          image: () => {
            document
              .getElementById(
                `${authContext.currentUser.id}-${parent}-${
                  id ? id : "new"
                }-image-uploader`
              )
              ?.click();
          },
        },
      },
    };
  }, [mode, authContext.currentUser.id, parent, id]);

  const onAnswerSubmit = async (event) => {
    event.preventDefault();
    if (content.length > MAX_CHAR_ANSWER) {
      setIsLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: `El contenido de la respuesta excede los ${MAX_CHAR_ANSWER} caracteres de largo máximo.`,
        onClose: setInformationModal({ isOpen: false, title: "", body: "" }),
      });
    }
    setIsLoading(true);
    const files = await getImages(images.filter((i) => utils.isNewImage(i))); //only the new
    awsUtils.replaceUploadImagesWeb(files, IMAGE_TYPE_CONTENT).then(
      (imagesUrl) => {
        const answerImages = [
          ...imagesUrl.map((i) => i.url),
          ...images.filter((i) => !utils.isNewImage(i)),
        ];
        setIsLoading(false);
        onSubmit(event, content, answerImages);
        setContent("");
        setImages([]);
      },
      (err) => {
        setIsLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Error",
          body: err.message,
        });
      }
    );
  };

  return (
    <>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
      <Media
        body
        className="d-flex align-items-start justify-content-start col-12 px-0"
      >
        {!editing ? (
          <div className="mt-1 flex-shrink-0">
            <div
              className="rounded-circle"
              style={{
                backgroundPosition: "center",
                backgroundImage: `url(${
                  authContext.currentUser.image || avatar
                })`,
                width: "32px",
                height: "32px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                cursor: "pointer",
                alignSelf: "center",
              }}
            ></div>
          </div>
        ) : null}
        <div
          className={`d-flex flex-column flex-grow-1 ${
            editing && mode === MODE_QUESTION_ANSWER ? "row" : ""
          }`}
          style={{ maxWidth: editing ? "100%" : "calc(100% - 32px)" }}
        >
          <div
            className={`flex-grow-1 ${
              answering ? "active " : ""
            }answer-quill col-12`}
            onClick={() => setAnswering(true)}
          >
            <Input
              id={`${authContext.currentUser.id}-${parent}-${
                id ? id : "new"
              }-image-uploader`}
              className="d-none"
              required={true}
              multiple={true}
              type="file"
              accept="image/*"
              onChange={(event) => {
                if (event.currentTarget.files?.length > 4) {
                  return setInformationModal({
                    isOpen: true,
                    title: "Error",
                    body: `No puedes subir más de 4 fotos.`,
                  });
                }
                setImages([
                  ...images,
                  ...[...event.currentTarget.files].map((file) =>
                    URL.createObjectURL(file)
                  ),
                ]);
              }}
            />
            <ReactQuill
              modules={modules}
              placeholder="Escribe el contenido de tu respuesta..."
              value={content}
              onChange={(c) => setContent(c.replace(/<p><br><\/p>/g, ""))}
            />
            {answering && images?.length ? (
              <div className="d-flex height-64 row mb-1">
                {images.map((image, i) => (
                  <div className="relative height-64 mx-2">
                    <Button
                      className="close"
                      style={{
                        top: "-10px",
                        right: "-8px",
                        position: "absolute",
                      }}
                      color="none"
                      onClick={() => {
                        const newimages = [...images];
                        newimages.splice(i, 1);
                        setImages([...newimages]);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} size="xs" />
                    </Button>
                    <img
                      alt={`AnswerImage-${i}`}
                      src={image}
                      className="rounded border height-100"
                    />
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          {answering ? (
            isLoading ? (
              <div className="min-width-50 float-right">
                <Loader size="sm" align="end" />
              </div>
            ) : (
              <Button
                disabled={!(images?.length || content?.length)}
                color="primary"
                className="align-self-end mt-1 small"
                size="sm"
                onClick={onAnswerSubmit}
              >
                {value ? "Guardar" : "Añadir"}{" "}
                {mode === MODE_QUESTION_ANSWER ? "Respuesta" : "Comentario"}
              </Button>
            )
          ) : null}
        </div>
      </Media>
    </>
  );
};

export default UserAnswer;
