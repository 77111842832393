import moment from "moment";
moment.locale("es-es");

const GENERAL_IMAGE = "general-images";

export const utils = {
  formatCurrency: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits,
    }).format(number),
  formatDecimal: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat("es-ES", { maximumFractionDigits }).format(number),
  formatPercent: (progress, total) =>
    `${total ? ((progress / total) * 100).toFixed(2) : 0}%`,
  formatDate: (date, format = "YYYY-MM-DD") =>
    (date ? moment(date) : moment()).format(format),
  nameInitials: (name) => {
    if (!/(.+)( )+(.+)/.test(name)) {
      return `${name[0]}${name[1] || ""}`;
    }
    const [, , , lastname] = name.match(/(.+)( )+(.+)/);
    return `${name[0]}${lastname[0]}`;
  },
  ordinalSuffix: (number) => {
    const last = number.toString().slice(-1);
    let ord = "";
    switch (last) {
      case "1":
        ord = "ra";
        break;
      case "2":
        ord = "da";
        break;
      case "3":
        ord = "ra";
        break;
      case "4":
        ord = "ta";
        break;
      case "5":
        ord = "ta";
        break;
      case "6":
        ord = "ta";
        break;
      case "7":
        ord = "ma";
        break;
      case "8":
        ord = "va";
        break;
      case "9":
        ord = "na";
        break;
      default:
        ord = "ma";
        break;
    }
    return `${number.toString()}${ord}`;
  },
  isNewImage: (image) => image.indexOf(GENERAL_IMAGE) < 0,
  handleError: (err) => {
    // I'm on the web!
    if (err?.response?.status === 401) {
      window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}`;
    } else if (err?.response?.status === 404) {
      window.location.href = "/error/404";
    } else if (err?.response?.status === 400 || err?.response?.status === 422) {
      throw err;
    } else {
      window.location.href = "/error/500";
    }
  },
};
