import React, { useEffect, useState } from "react";

import {
  Form,
  Dropdown,
  DropdownToggle,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
} from "reactstrap";

import { useHistory } from "react-router-dom";
import InformationModal from "../InformationModal";
import { useSearch } from "../../providers";

const ON_SEARCH = "ON_SEARCH";

const TopbarSearch = () => {
  const [searchContext, setSearchContext] = useSearch();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [search, setSearch] = useState(searchContext?.term || "");

  const history = useHistory();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSearch = (event) => {
    event.preventDefault();
    setSearchContext({
      action: ON_SEARCH,
      payload: search,
    });
    history.push(`/search`);
  };

  useEffect(() => {
    if (history.location?.pathname !== `/search`) {
      setSearch("");
    }
  }, [history]);

  return (
    <>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={() => setDropdownOpen(!dropdownOpen)}
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <DropdownToggle tag="a" className="d-none"></DropdownToggle>
        <Form className="px-2 col-12" onSubmit={onSearch}>
          <InputGroup>
            <Input
              className="form-control dropdown-toggle"
              placeholder={"Buscar..."}
              required={true}
              maxLength={50}
              value={search}
              onChange={(evt) => setSearch(evt.target.value)}
            />
            <span className="mdi mdi-magnify search-icon mr-2"></span>
            <InputGroupAddon addonType="append">
              <Button
                className="text-white bg-primary border-0 cursor-pointer"
                type="submit"
              >
                Buscar
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Form>
      </Dropdown>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </>
  );
};

export default TopbarSearch;
