import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import { ListGroup, ListGroupItem, Button } from "reactstrap";
import { useEffect } from "react";
import Loader from "../../components/Loader";
import avatar from "../../assets/images/placeholder-3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useSearch } from "../../providers";
import { commonApi } from "../../services";

const MAX_PAGE_SIZE = 999;

const UserResults = ({ toggleFilterModal }) => {
  const [searchContext] = useSearch();
  const [items, setItems] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (searchContext?.term?.length) {
      setIsLoading(true);
      commonApi
        .getUsers({ search: searchContext?.term, pageSize: MAX_PAGE_SIZE })
        .then((items) => {
          setItems(items);
          setIsLoading(false);
        });
    }
  }, [searchContext?.term]);

  return (
    <ListGroup
      flush
      className="border text-left col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 px-0"
    >
      <ListGroupItem
        className="font-weight-bold text-body bg-light text-center border-bottom d-flex justify-content-between align-items-center"
        tag="div"
      >
        <div className="col-3"></div>
        <div className="col-3">
          {items.count || 0} Usuario{items.count !== 1 && "s"}
        </div>
        <div className="col-3 d-flex justify-content-end">
          <Button className="d-block d-lg-none" onClick={toggleFilterModal}>
            <FontAwesomeIcon icon={faFilter} />
          </Button>
        </div>
      </ListGroupItem>
      {isLoading ? (
        <div className="min-width-50 my-2">
          <Loader size="sm" />
        </div>
      ) : items?.length ? (
        items.slice(0, 5).map((user, index) => (
          <ListGroupItem
            key={index}
            action
            onClick={() => history.push(`/users/${user?.id}`)}
            className="cursor-pointer pb-2 border-bottom d-flex align-items-center justify-content-between"
            tag="div"
          >
            <div
              className="rounded"
              style={{
                backgroundPosition: "center",
                backgroundImage: `url(${user?.image || avatar})`,
                width: "80px",
                height: "80px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                alignSelf: "center",
              }}
            ></div>
            <div className="d-flex flex-column justify-content-end">
              <h4 className="mb-0 mt-2 text-right">{`${user?.firstName} ${user?.lastName}`}</h4>
              <div className="text-muted">
                <small>
                  {user?.totalQuestions}{" "}
                  {user?.totalQuestions === 1 ? "pregunta" : "preguntas"}
                </small>
                <small>
                  , {user?.totalFollowers}{" "}
                  {user?.totalFollowers === 1 ? "seguidor" : "seguidores"}
                </small>
              </div>
            </div>
          </ListGroupItem>
        ))
      ) : (
        <ListGroupItem className="pb-2 text-center" tag="div">
          No hay resultados
        </ListGroupItem>
      )}
    </ListGroup>
  );
};

export default UserResults;
