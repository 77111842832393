import React from "react";
import Main from "../components/Main";

const Auth = ({ children }) => (
  <Main className="auth-fluid">
    <div className="auth-fluid-form-box p-2 px-md-3 py-md-2">
      <div className="align-items-center d-flex h-100 flex-column">
        {children}
      </div>
    </div>
    <div className="auth-fluid-right text-center">
      <div className="auth-user-testimonial"></div>
    </div>
  </Main>
);

export default Auth;
