import React from "react";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";

const InformationModal = ({
  onClose,
  title,
  body,
  rawBody = false,
  size = "md",
}) => {
  return (
    <Modal isOpen={true} onClosed={onClose} size={size} toggle={onClose}>
      {title?.length ? <ModalHeader>{title}</ModalHeader> : null}
      <ModalBody>
        {rawBody ? (
          body
        ) : (
          <div dangerouslySetInnerHTML={{ __html: body }}></div>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"primary"} onClick={onClose}>
          Cerrar
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default InformationModal;
