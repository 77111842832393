import React from "react";

import { Card, CardBody } from "reactstrap";
import Question from "../pages/feed/Question";
import Loader from "./Loader";

import InfiniteScroll from "react-infinite-scroll-component";
import { useCategories } from "../providers";
import { usePaginatedResource } from "../utils/hooks";

const CategoryQuestions = ({ categoryId }) => {
  const { categoriesState } = useCategories();
  const [isLoading, fetchMore] = usePaginatedResource({
    categoryId,
    type: "CategoryQuestions",
    pageSize: 5,
  });

  const questions = categoriesState?.categoryQuestions[categoryId] || [];

  return (
    <>
      <InfiniteScroll
        dataLength={questions.length} //This is important field to render the next data
        next={fetchMore}
        hasMore={
          categoriesState?.categoryQuestions.page <
          categoriesState?.categoryQuestions.totalPages
        }
        loader={<h4 className="mb-3 text-center">Cargando..</h4>}
        endMessage={
          <Card className="mb-0 border">
            <CardBody className="text-center">
              <span>No hay más preguntas para mostrar</span>
            </CardBody>
          </Card>
        }
        scrollableTarget="wrapper"
      >
        {questions.length
          ? questions.map((question) => (
              <Question
                readOnly={false}
                defaultExpanded={false}
                question={question}
                key={question.id}
              />
            ))
          : null}
      </InfiniteScroll>
      {isLoading ? (
        <div className="min-width-50 my-2">
          <Loader size="sm" />
        </div>
      ) : null}
    </>
  );
};

export default CategoryQuestions;
