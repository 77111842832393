import React, { useReducer, createContext, useContext } from "react";

const initialState = {
  refresh: false,
  questions: [],
  page: 0,
  pageSize: 5,
  count: 0,
  totalPages: 0,
  byId: {},
};

const questionsById = (questions) => {
  return questions.reduce((map, question) => {
    map[question.id] = { ...question };
    return map;
  }, {});
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "getQuestions": {
      //we need to append the new "page" and not overwrite, given that is infinite scroll
      const questions =
        state.page === 0
          ? action.payload?.questions || []
          : [
              ...state.questions,
              ...(action.payload?.questions || []).filter(
                (q) => !state.byId[q.id]
              ),
            ];

      const byId = questionsById(questions);

      return {
        ...state,
        questions,
        count: action.payload.count,
        totalPages: action.payload.totalPages,
        byId: {
          ...state.byId,
          ...byId,
        },
      };
    }
    case "refreshQuestions": {
      return {
        ...initialState,
        refresh: !state.refresh,
      };
    }
    case "refreshQuestion": {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.questionId]: {
            ...state.byId[action.payload.questionId],
            refresh: !state.byId[action.payload.questionId]?.refresh,
          },
        },
      };
    }
    case "onNewPage": {
      if (state.page === state.totalPages) {
        return state;
      }
      return {
        ...state,
        page: state.page + 1,
      };
    }
    default:
      return state;
  }
};

const QuestionsContext = createContext({
  questionsState: initialState,
  dispatchQuestionsChange: () => void 0,
});

const QuestionsProvider = ({ children }) => {
  const [questionsState, dispatchQuestionsChange] = useReducer(
    reducer,
    initialState
  );
  return (
    <QuestionsContext.Provider
      value={{
        questionsState,
        dispatchQuestionsChange,
      }}
    >
      {children}
    </QuestionsContext.Provider>
  );
};

const useQuestions = () => useContext(QuestionsContext);

export { QuestionsProvider, useQuestions };
