import config from "../config/config";
import { awsApi } from "../services/AwsServices";
import Resizer from "react-image-file-resizer";
import {
  IMAGE_QUALITY,
  IMAGE_THUMB_QUALITY,
  IMAGE_TYPE_PROFILE,
  MAX_IMAGE_THUMB_WIDTH,
  MAX_IMAGE_WIDTH,
  THUMBNAIL,
} from "../constants";

const resizeFile = (file, size, quality) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      size,
      size,
      file.type.replace("image/", ""),
      quality,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });

const uploadImagesToS3Web = async (documents, imageByName) => {
  const signedURLs = await awsApi.signDocuments({ documents });
  const elements = signedURLs
    .filter((signedURL) => signedURL.success)
    .map((signedURL) => {
      const { blob } = imageByName[signedURL.fileName];
      return {
        type: blob.type,
        url: signedURL.signedRequest,
        blob,
        fileUrl: signedURL.url,
        fileName: signedURL.fileName,
      };
    });
  const result = await Promise.all(
    elements.map((element) => {
      const isThumb = element.url.indexOf(THUMBNAIL) > -1;
      const size = isThumb ? MAX_IMAGE_THUMB_WIDTH : MAX_IMAGE_WIDTH;
      const quality = isThumb ? IMAGE_THUMB_QUALITY * 100 : IMAGE_QUALITY * 100;

      return resizeFile(element.blob, size, quality).then((blob) =>
        awsApi.putDocumentsToS3({
          ...element,
          blob,
        })
      );
    })
  );
  //upload regular and thumbnail version, but return only the regular ones to fill the URL of the image in the question
  //then to load the thumbnail, only need to replace the word "regular" in the URL by "thumbnail"
  return result.filter((i) => i.url.indexOf(THUMBNAIL) === -1);
};

export const utils = {
  replaceUploadImagesWeb: function (images, type) {
    const id = new Date().getTime();
    const imageByName = {};
    let readyToSignDocuments;
    try {
      readyToSignDocuments = images.flatMap((img, index) => {
        const blob = new Blob([img], { type: img.type });
        const fileName = `q-image-${id}-${index}.${blob.type.replace(
          "image/",
          ""
        )}`;
        imageByName[fileName] = { img, blob };
        //upload two images, one to thumbnail bucket and other to regular bucket
        return [
          {
            fileName,
            fileType: blob.type,
            bucket:
              type === IMAGE_TYPE_PROFILE
                ? config.profileImagesBucket
                : config.generalImagesBucket,
            method: "putObject",
          },
          {
            fileName,
            fileType: blob.type,
            bucket:
              type === IMAGE_TYPE_PROFILE
                ? config.profileImagesThumbnailBucket
                : config.generalImagesThumbnailBucket,
            method: "putObject",
          },
        ];
      });
    } catch (err) {
      return Promise.reject(err);
    }
    return uploadImagesToS3Web(readyToSignDocuments, imageByName);
  },
};
