export const VOTE_POSITIVE = 1;
export const VOTE_NEGATIVE = 2;

export const DATE_FORMAT = "Do MMMM YYYY";

export const NOTIFICATION_TYPE_NEW_ANSWER_TO_MY_QUESTION =
  "NOTIFICATION_TYPE_NEW_ANSWER_TO_MY_QUESTION";
export const NOTIFICATION_TYPE_NEW_COMMENT_TO_MY_QUESTION =
  "NOTIFICATION_TYPE_NEW_COMMENT_TO_MY_QUESTION";
export const NOTIFICATION_TYPE_NEW_COMMENT_TO_MY_ANSWER =
  "NOTIFICATION_TYPE_NEW_COMMENT_TO_MY_ANSWER";
export const NOTIFICATION_TYPE_NEW_COMMENT_TO_ANSWER_I_COMMENTED =
  "NOTIFICATION_TYPE_NEW_COMMENT_TO_ANSWER_I_COMMENTED";

export const NOTIFICATION_STATUS_UNREAD = 1;
export const NOTIFICATION_STATUS_READ = 2;

export const SOCKET_STATUS_DISCONNECTED = "SOCKET_STATUS_DISCONNECTED";
export const SOCKET_STATUS_CONNECTED = "SOCKET_STATUS_CONNECTED";

export const TRUNCATE_HTML_LENGTH = 160;

export const REPORT_TYPE_QUESTION = "REPORT_TYPE_QUESTION";
export const REPORT_TYPE_QUESTION_ANSWER = "REPORT_TYPE_QUESTION_ANSWER";
export const REPORT_TYPE_COMMENT = "REPORT_TYPE_COMMENT";
export const REPORT_TYPE_USER = "REPORT_TYPE_USER";

export const CONTENT_REPORT_REASONS = [
  {
    id: "HARASSMENT",
    title: "Acoso",
    description: "Despreciar o contrariar a una persona o grupo",
  },
  {
    id: "SPAM",
    title: "No deseado",
    description: "Promoción no divulgada de un enlace o producto",
  },
  {
    id: "PLAGIARISM",
    title: "Plagio",
    description:
      "Reutilización de contenido sin atribución (enlace y citas en bloque)",
  },
  {
    id: "BAD_IMAGE",
    title: "Mala imagen",
    description: "El contenido incluye una imagen que infringe la política",
  },
  {
    id: "FACTUALLY_INCORRECT",
    title: "Realmente incorrecto",
    description:
      "Conclusiones primarias sustancialmente incorrectas y / o incorrectas",
  },
  {
    id: "ADULT_CONTENT",
    title: "Contenido para adultos",
    description:
      "Sexualmente explícito, pornográfico o inapropiado de cualquier otro modo",
  },
];

export const USER_REPORT_REASONS = [
  {
    id: "HARASSMENT",
    title: "Acoso",
    description: "Despreciar o contrariar a una persona o grupo",
  },
  {
    id: "SPAM",
    title: "No deseado",
    description: "Promoción no divulgada de un enlace o producto",
  },
  {
    id: "PLAGIARISM",
    title: "Plagio",
    description:
      "Reutilización de contenido sin atribución (enlace y citas en bloque)",
  },
  {
    id: " QUESTION_POLICY",
    title: "Política de preguntas",
    description:
      "Hacer repetidamente preguntas poco sinceras o que infrinjan las políticas",
  },
  {
    id: "NAME_POLICY",
    title: "Violación de la política de nombres",
    description:
      "Usar un nombre que se haga pasar por alguien o que contenga insultos o contenido explícito.",
  },
];

export const IMAGE_TYPE_PROFILE = "IMAGE_TYPE_PROFILE";
export const THUMBNAIL = "-images-thumbnail-";
export const MAX_IMAGE_WIDTH = 500; // Max image width in pixeles
export const MAX_IMAGE_THUMB_WIDTH = 164; // Max image width in pixeles
export const IMAGE_QUALITY = 0.8; // Save images with 80% of the original quality
export const IMAGE_THUMB_QUALITY = 0.5; // Save thumbs with 60% of the original quality
