import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import { ListGroup, ListGroupItem, Button } from "reactstrap";
import { useEffect } from "react";
import Loader from "../../components/Loader";
import CategoryItem from "../../components/CategoryItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useSearch } from "../../providers";
import { commonApi } from "../../services";

const MAX_PAGE_SIZE = 999;

const CategoryResults = ({ toggleFilterModal }) => {
  const [searchContext] = useSearch();
  const [items, setItems] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (searchContext?.term?.length) {
      setIsLoading(true);
      commonApi
        .getCategories({ search: searchContext?.term, pageSize: MAX_PAGE_SIZE })
        .then((items) => {
          setItems(items);
          setIsLoading(false);
        });
    }
  }, [searchContext?.term]);

  return (
    <ListGroup
      flush
      className="border text-left col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 px-0"
    >
      <ListGroupItem
        className="font-weight-bold text-body bg-light text-center border-bottom d-flex justify-content-between align-items-center"
        tag="div"
      >
        <div className="col-3"></div>
        <span>
          {items?.length || 0} Tema{items?.length !== 1 && "s"} de Interés
        </span>
        <div className="col-3 d-flex justify-content-end">
          <Button className="d-block d-lg-none" onClick={toggleFilterModal}>
            <FontAwesomeIcon icon={faFilter} />
          </Button>
        </div>
      </ListGroupItem>
      {isLoading ? (
        <div className="min-width-50 my-2">
          <Loader size="sm" />
        </div>
      ) : items?.length ? (
        items.map((category, index) => (
          <ListGroupItem
            key={category.id}
            action
            className="pb-2 cursor-pointer border-bottom"
            onClick={() => history.push(`/categories/${category?.id}`)}
            tag="div"
          >
            <CategoryItem key={category.id} category={category} index={index} />
          </ListGroupItem>
        ))
      ) : (
        <ListGroupItem className="pb-2 text-center" tag="div">
          No hay resultados
        </ListGroupItem>
      )}
    </ListGroup>
  );
};

export default CategoryResults;
