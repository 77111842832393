import React, { useReducer, createContext, useContext } from "react";
import {
  NOTIFICATION_STATUS_READ,
  SOCKET_STATUS_CONNECTED,
  SOCKET_STATUS_DISCONNECTED,
} from "../constants";

const initialState = {
  refresh: false,
  notifications: [],
  page: 1,
  sizePerPage: 10,
  status: SOCKET_STATUS_DISCONNECTED,
  socket: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "initNotifications": {
      return {
        ...state,
        status: SOCKET_STATUS_CONNECTED,
        socket: action.payload.socket,
      };
    }
    case "disconnectNotifications": {
      return {
        ...state,
        status: SOCKET_STATUS_DISCONNECTED,
        socket: null,
      };
    }
    case "getNotifications": {
      return {
        ...state,
        // TODO backend should return notfication order by id desc by default
        notifications: action.payload.sort((a, b) => b.id - a.id),
      };
    }
    case "refreshNotifications": {
      return {
        ...state,
        refresh: !state.refresh,
      };
    }
    case "onNewNotification": {
      if (state.notifications.find((n) => n.id === action.payload.id)) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    }
    case "onDeleteNotification": {
      const notifications = [...state.notifications];
      notifications.splice(action.payload.index, 1);
      return {
        ...state,
        notifications,
      };
    }
    case "onReadNotification": {
      const index = state.notifications.findIndex(
        (notification) => notification.id === action.payload.id
      );
      const notification = state.notifications[index];
      if (!notification) {
        return {
          ...state,
        };
      }
      notification.status = NOTIFICATION_STATUS_READ;
      const notifications = [...state.notifications];
      notifications.splice(index, 1, notification);
      return {
        ...state,
        notifications,
      };
    }
    case "onReadAllNotifications": {
      return {
        ...state,
        notifications: state.notifications.map((not) => ({
          ...not,
          status: NOTIFICATION_STATUS_READ,
        })),
      };
    }
    case "onPageChange": {
      return {
        ...state,
        page: action.payload.page,
      };
    }
    case "resetStatus":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const NotificationsContext = createContext({
  notificationsState: initialState,
  dispatchNotificationsChange: () => void 0,
});

const NotificationsProvider = ({ children }) => {
  const [notificationsState, dispatchNotificationsChange] = useReducer(
    reducer,
    initialState
  );

  return (
    <NotificationsContext.Provider
      value={{
        notificationsState,
        dispatchNotificationsChange,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

const useNotifications = () => useContext(NotificationsContext);

export { NotificationsProvider, useNotifications };
