import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  Alert,
  FormGroup,
  Label,
  CustomInput,
} from "reactstrap";

import Loader from "../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import InformationModal from "../InformationModal";
import { useAuth } from "../../providers";
import { utils } from "../../utils/aws";

const IMAGE_TYPE_PROFILE = "IMAGE_TYPE_PROFILE";
const SIGN_UP_MODE = "SIGN_UP_MODE";
const EDIT_MODE = "EDIT_MODE";

const GENDERS = [
  {
    label: "Masculino",
    key: "MASCULINO",
  },
  {
    label: "Femenino",
    key: "FEMENINO",
  },
  {
    label: "Otro",
    key: "OTRO",
  },
];

const UserModal = ({ defaultData, onSubmit, onClose, mode = EDIT_MODE }) => {
  const [authContext] = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [localData, setLocalData] = useState({
    gender: defaultData?.gender || authContext.currentUser?.gender,
    firstName: defaultData?.firstName || authContext.currentUser?.firstName,
    lastName: defaultData?.lastName || authContext.currentUser?.lastName,
    email: defaultData?.email || authContext.currentUser?.email,
    description:
      defaultData?.description || authContext.currentUser?.description,
    image: defaultData?.image || authContext.currentUser?.image,
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: () => {},
  });

  const doSubmit = (event) => {
    event.preventDefault();
    if (!localData.image || typeof localData.image === "string") {
      onSubmit(localData);
    } else {
      setIsLoading(true);
      utils.replaceUploadImagesWeb([localData.image], IMAGE_TYPE_PROFILE).then(
        ([imageUrl]) => {
          setIsLoading(false);
          onSubmit({
            ...localData,
            image: imageUrl.url,
          });
        },
        (err) => {
          setIsLoading(false);
          setError(err.message);
        }
      );
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({
          isOpen: false,
          title: "",
          body: "",
          onClose: () => {},
        })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} toggle={onClose}>
      <ModalHeader>
        {mode === SIGN_UP_MODE ? "Registro de Usuario" : "Editar Perfil"}
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          <FormGroup>
            <Label className="font-weight-500">
              <span>Nombre</span>
              <span className="text-danger ml-1">*</span>
            </Label>
            <Input
              maxLength={50}
              required={true}
              name="firstName"
              className="mb-1 form-control"
              value={localData.firstName}
              placeholder="Ingresa tu nombre"
              onChange={(event) =>
                setLocalData({
                  ...localData,
                  firstName: event.currentTarget.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label className="font-weight-500">
              <span>Apellido</span>
              <span className="text-danger ml-1">*</span>
            </Label>
            <Input
              maxLength={50}
              required={true}
              name="lastName"
              className="mb-1 form-control"
              value={localData.lastName}
              placeholder="Ingresa tu apellido"
              onChange={(event) =>
                setLocalData({
                  ...localData,
                  lastName: event.currentTarget.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label className="font-weight-500">
              <span>Email</span>
              <span className="text-danger ml-1">*</span>
            </Label>
            <Input
              maxLength={50}
              required={true}
              name="email"
              className="mb-1 form-control"
              value={localData.email}
              placeholder="Ingresa tu email"
              onChange={(event) =>
                setLocalData({
                  ...localData,
                  email: event.currentTarget.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label className="font-weight-500">
              <span>Género</span>
              <span className="text-danger ml-1">*</span>
            </Label>
            <CustomInput
              required
              type="select"
              name="genderSelect"
              id="genderSelect"
              onChange={(event) =>
                setLocalData({
                  ...localData,
                  gender: event.currentTarget.value,
                })
              }
              value={localData.gender}
            >
              <option value={""}>Selecciona tu género</option>
              {GENDERS.map((gender) => (
                <option key={gender.key} value={gender.key}>
                  {gender.label}
                </option>
              ))}
            </CustomInput>
          </FormGroup>
          <FormGroup>
            <Label className="font-weight-500">Sobre mí</Label>
            <Input
              type="textarea"
              maxLength={500}
              name="description"
              className="mb-1 form-control"
              value={localData.description || ""}
              placeholder="Ingresa una breve descripción sobre tí"
              onChange={(event) =>
                setLocalData({
                  ...localData,
                  description: event.currentTarget.value,
                })
              }
            />
            <small className="text-muted ml-1">
              Ingresa una breve descripción sobre tí para que vean los demas
              usuarios
            </small>
          </FormGroup>
          <FormGroup className="mb-0">
            <Label className="font-weight-500">Foto de perfil</Label>
            <div className="d-flex">
              {localData.image ? (
                <div
                  className="rounded mr-2"
                  style={{
                    backgroundPosition: "center",
                    backgroundImage: `url(${
                      typeof localData.image === "string"
                        ? localData.image
                        : URL.createObjectURL(localData.image)
                    })`,
                    width: "100px",
                    height: "100px",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    cursor: "pointer",
                    alignSelf: "center",
                  }}
                ></div>
              ) : null}
              <CustomInput
                id="profile-img"
                type="file"
                name="image"
                className="mb-1 form-control border-0"
                placeholder="Selecciona una imagen"
                onChange={(event) =>
                  setLocalData({
                    ...localData,
                    image: event.currentTarget.files[0],
                  })
                }
              />
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="d-flex flex-column">
          {!isLoading && !!error ? (
            <Alert className="alert-outline cursor-pointer" color="warning">
              <div className="alert-icon">
                <FontAwesomeIcon icon={faBell} fixedWidth />
              </div>
              <div className="alert-message text-left pr-5">
                <small>{error}</small>
              </div>
            </Alert>
          ) : null}
          <div className="d-flex justify-content-between col-12 px-0 align-items-center">
            <Button color={"secondary"} onClick={onClose} size="sm">
              Cancelar
            </Button>
            {isLoading ? (
              <div className="min-width-50">
                <Loader size="sm" align="end" />
              </div>
            ) : (
              <Button color="primary" type="submit" size="sm">
                Guardar
              </Button>
            )}
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UserModal;
