import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

const RequiredSelect = (props) => {
  const { SelectComponent, required, isDisabled, onChange = () => {} } = props;
  const enableRequired = !isDisabled;

  const [state, setState] = useState({
    value: props.value || "",
  });
  const selectRef = useRef(null);
  const inputRef = useRef(null);

  const onChangeRequired = (value, actionMeta) => {
    onChange(value, actionMeta);
    inputRef.current.setCustomValidity("");
    setState({ value });
  };

  const getValue = () => {
    if (props.value !== undefined) {
      return props.value;
    }
    return state.value || "";
  };

  return (
    <div>
      <SelectComponent {...props} ref={selectRef} onChange={onChangeRequired} />
      {enableRequired && (
        <input
          ref={inputRef}
          tabIndex={-1}
          autoComplete="off"
          style={{
            opacity: 0,
            width: "100%",
            height: 0,
            position: "absolute",
          }}
          defaultValue={getValue()}
          required={required}
          onInvalid={(e) =>
            e.target.setCustomValidity("Selecciona alguna categoría")
          }
        />
      )}
    </div>
  );
};

RequiredSelect.protoTypes = {
  selectComponent: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

export default RequiredSelect;
