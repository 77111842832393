import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";

import Loader from "../../components/Loader";
import { useHistory } from "react-router-dom";
import CategoryItem from "../../components/CategoryItem";
import { useAuth, useCategories } from "../../providers";
import { commonApi } from "../../services";

const MAX_ITEMS = 10;

const MyCategories = () => {
  const history = useHistory();
  const { categoriesState, dispatch } = useCategories();
  const [authContext] = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const categories = categoriesState?.categories;

  useEffect(() => {
    if (!categories?.length) {
      setIsLoading(true);
      commonApi.getCategories().then((categories) => {
        setIsLoading(false);
        dispatch({
          type: "getCategoriesSuccess",
          payload: categories,
        });
      });
    }
  }, [dispatch, authContext.currentUser.categoryFollows, categories]);

  const myCategories = categories
    .filter((category) => categoriesState?.followed.indexOf(category.id) > -1)
    .sort((x, y) => y.popularity - x.popularity);

  return (
    <React.Fragment>
      <Card className="categories-card d-none d-lg-block">
        <CardHeader className="app-search">
          <h4 className="text-center">Mis temas de interés</h4>
        </CardHeader>
        <CardBody className="py-2 px-0 mt-n2">
          {isLoading ? (
            <div className="min-width-50 my-2">
              <Loader size="sm" />
            </div>
          ) : (
            <div className="inbox-widget">
              {myCategories.slice(0, MAX_ITEMS).map((category, index) => (
                <CategoryItem
                  key={category.id}
                  category={category}
                  index={index}
                />
              ))}
              {myCategories.length > MAX_ITEMS ? (
                <div className="inbox-item d-flex align-items-center justify-content-between">
                  <div
                    onClick={() => history.push(`/categories`)}
                    className="cursor-pointer flex-grow-1 d-flex align-items-center justify-content-start px-2 pb-0"
                  >
                    <p className="col-12 text-center mb-0 inbox-item-author mb-n2 text-primary font-weight-500">
                      Ver Todos
                    </p>
                  </div>
                </div>
              ) : null}
              {!myCategories?.length ? (
                <div className="text-center">No hay temas para mostrar</div>
              ) : null}
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default MyCategories;
