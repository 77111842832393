import React from "react";
import {
  AuthProvider,
  CategoriesProvider,
  NotificationsProvider,
  ProfileProvider,
  QuestionsProvider,
  SearchProvider,
} from "./providers";
import Routes from "./routes/Routes";

// Themes
import "./assets/scss/modern.scss";

class App extends React.Component {
  componentDidMount() {
    // Remove `active` className from `.splash` element in `public/index.html`
    !document.querySelector(".splash") ||
      document.querySelector(".splash")?.classList.remove("active");
  }

  render() {
    return (
      <AuthProvider>
        <QuestionsProvider>
          <CategoriesProvider>
            <ProfileProvider>
              <NotificationsProvider>
                <SearchProvider>
                  <Routes />
                </SearchProvider>
              </NotificationsProvider>
            </ProfileProvider>
          </CategoriesProvider>
        </QuestionsProvider>
      </AuthProvider>
    );
  }
}

export default App;
