import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText,
} from "reactstrap";

import Loader from "../components/Loader";
import InformationModal from "../components/InformationModal";
import { useHistory } from "react-router-dom";
import useElementSize from "../hooks/useElementSize";
import { useCategories } from "../providers";
import { commonApi } from "../services";
import config from "../config/config";

const UNFOLLOW = 1;
const FOLLOW = 2;

const getImage = (category) =>
  category.image
    ? `${config.apiURL}/images/categories/${category.image}`
    : `${config.apiURL}/images/categories/category-placeholder-4.png`;

const Categories = () => {
  const history = useHistory();

  const cardRef = useRef(null);

  const { width } = useElementSize(cardRef);

  const { categoriesState, dispatch } = useCategories();

  const [isLoading, setIsLoading] = useState(false);

  const [followLoading, setFollowLoading] = useState({});

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const categories = categoriesState?.categories;

  const isFollowing = (id) =>
    categoriesState?.followed.indexOf(parseInt(id)) > -1;

  useEffect(() => {
    if (!categories?.length) {
      setIsLoading(true);
      commonApi.getCategories().then((categories) => {
        setIsLoading(false);
        dispatch({
          type: "getCategoriesSuccess",
          payload: categories,
        });
      });
    }
  }, [dispatch, categories]);

  const onFollow = (id, action) => {
    const oldFollowed = categoriesState?.followed.slice();
    const followed = categoriesState?.followed;
    if (action === UNFOLLOW) {
      followed.splice(followed.indexOf(id), 1);
    } else {
      followed.push(id);
    }
    dispatch({
      type: "followCategoriesSuccess",
      payload: { followed: followed, oldFollowed: oldFollowed },
    });
  };

  const toggleFollow = (id) => {
    setFollowLoading({ [id]: true });
    if (isFollowing(id)) {
      if (categoriesState?.followed.length === 1) {
        setFollowLoading({ [id]: false });
        return setInformationModal({
          isOpen: true,
          title: "Dejar de seguir",
          body: "Debes seguir al menos un tema de interés.",
        });
      }
      commonApi
        .unfollowCategory(id)
        .then(() => {
          setFollowLoading({ [id]: false });
          onFollow(parseInt(id), UNFOLLOW);
        })
        .catch(() => {
          setFollowLoading({ [id]: false });
          onFollow(parseInt(id), FOLLOW);
        });
    } else {
      commonApi
        .followCategories([id])
        .then(() => {
          setFollowLoading({ [id]: false });
          onFollow(parseInt(id), FOLLOW);
        })
        .catch(() => {
          setFollowLoading({ [id]: false });
          onFollow(parseInt(id), UNFOLLOW);
        });
    }
  };

  const followedCategories = categories
    .filter((category) => categoriesState?.followed.indexOf(category.id) > -1)
    .sort((x, y) => y.popularity - x.popularity);

  const otherCategories = categories
    .filter((category) => categoriesState?.followed.indexOf(category.id) === -1)
    .sort((x, y) => y.popularity - x.popularity);

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="min-width-50 my-2">
          <Loader size="sm" />
        </div>
      ) : (
        <Row className="mt-3">
          <Col className="order-md-1 order-lg-1 col-12">
            <h4 className="pl-2">Temas de Interés Seguidos</h4>
            <div className="p-0 d-flex flex-wrap mt-2">
              {followedCategories.length
                ? followedCategories.map((category, i) => (
                    <div
                      key={category.id}
                      className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex"
                    >
                      <Card
                        className="col-12 px-0 border cursor-pointer"
                        onClick={() =>
                          history.push(`/categories/${category.id}`)
                        }
                      >
                        <CardHeader className="p-0">
                          <div
                            ref={i === 0 ? cardRef : null}
                            style={{
                              backgroundPosition: "center",
                              backgroundImage: `url(${getImage(category)})`,
                              width: "100%",
                              height: width / 2,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </CardHeader>
                        <CardBody className="py-3 d-flex flex-column justify-content-between">
                          <div className="text-center">
                            <CardTitle tag="h5" className="">
                              {category?.name || ""}
                            </CardTitle>
                            <CardSubtitle tag="h6" className="mb-2 text-muted">
                              <small>
                                {category?.totalQuestions} preguntas
                              </small>
                              <small>
                                , {category?.totalFollowers}{" "}
                                {category?.totalFollowers === 1
                                  ? "seguidor"
                                  : "seguidores"}
                              </small>
                            </CardSubtitle>
                            <CardText className="">
                              <small>{category?.description}</small>
                            </CardText>
                          </div>
                          <div className="d-flex align-items-center justify-content-center mt-2">
                            {followLoading[category.id] ? (
                              <Loader size="sm" align="center" />
                            ) : isFollowing(category.id) ? (
                              <Button
                                onClick={(event) => {
                                  event.stopPropagation();
                                  toggleFollow(category.id);
                                }}
                                size="sm"
                                className="btn btn-danger cursor-pointer"
                              >
                                Dejar de Seguir
                              </Button>
                            ) : (
                              <Button
                                onClick={(event) => {
                                  event.stopPropagation();
                                  toggleFollow(category.id);
                                }}
                                size="sm"
                                className="btn btn-success cursor-pointer"
                              >
                                Seguir
                              </Button>
                            )}
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  ))
                : null}
            </div>
            <h4 className="pl-2">Otros Temas de Interés</h4>
            <div className="p-0 d-flex flex-wrap mt-2">
              {otherCategories.length
                ? otherCategories.map((category, i) => (
                    <div
                      key={category.id}
                      className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex"
                    >
                      <Card
                        className="col-12 px-0 border cursor-pointer"
                        onClick={() =>
                          history.push(`/categories/${category.id}`)
                        }
                      >
                        <CardHeader className="p-0">
                          <div
                            ref={i === 0 ? cardRef : null}
                            style={{
                              backgroundPosition: "center",
                              backgroundImage: `url(${getImage(category)})`,
                              width: "100%",
                              height: width / 2,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </CardHeader>
                        <CardBody className="py-3 d-flex flex-column justify-content-between">
                          <div className="text-center">
                            <CardTitle className="" tag="h5">
                              {category?.name || ""}
                            </CardTitle>
                            <CardSubtitle tag="h6" className="mb-2 text-muted">
                              <small>
                                {category?.totalQuestions} preguntas
                              </small>
                              <small>
                                , {category?.totalFollowers}{" "}
                                {category?.totalFollowers === 1
                                  ? "seguidor"
                                  : "seguidores"}
                              </small>
                            </CardSubtitle>
                            <CardText className="">
                              <small>{category?.description}</small>
                            </CardText>
                          </div>
                          <div className="d-flex align-items-center justify-content-center mt-2">
                            {followLoading[category.id] ? (
                              <Loader size="sm" align="center" />
                            ) : isFollowing(category.id) ? (
                              <Button
                                onClick={(event) => {
                                  event.stopPropagation();
                                  toggleFollow(category.id);
                                }}
                                size="sm"
                                className="btn btn-danger cursor-pointer"
                              >
                                Dejar de Seguir
                              </Button>
                            ) : (
                              <Button
                                onClick={(event) => {
                                  event.stopPropagation();
                                  toggleFollow(category.id);
                                }}
                                size="sm"
                                className="btn btn-success cursor-pointer"
                              >
                                Seguir
                              </Button>
                            )}
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  ))
                : null}
            </div>
          </Col>
        </Row>
      )}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </React.Fragment>
  );
};

export default Categories;
