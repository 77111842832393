import React, { useReducer, createContext, useContext } from "react";

const initialState = {
  byId: {},
  categories: [],
  followed: [],
  categoryQuestions: { totalPages: 0, page: 0 },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "getCategoriesSuccess": {
      // TODO -> When backend returns image and description remove this extended cateogory thing
      const itemsById = (action.payload || []).reduce((prev, current) => {
        return prev
          ? { ...prev, [current.id]: current }
          : { [current.id]: current };
      }, {});

      return {
        ...state,
        categories: action.payload,
        followed: (action.payload || [])
          .filter((c) => c?.currentUserFollows)
          .map((c) => c.id),
        byId: itemsById,
      };
    }
    case "getCategoryQuestionsSuccess": {
      const { payload } = action;
      const { categoryId, data, page, totalPages } = payload;

      //we need to append the new "page" and not overwrite, given that is infinite scroll
      const questionsForCategory =
        page === 0
          ? data
          : [...(state.categoryQuestions[categoryId] || []), ...data];

      return {
        ...state,
        categoryQuestions: {
          ...state.categoryQuestions,
          [categoryId]: questionsForCategory,
          page,
          totalPages,
        },
      };
    }
    case "followCategoriesSuccess": {
      let newCategories = state.categories;
      const differenceId = action.payload.followed
        .filter((x) => !action.payload.oldFollowed.includes(x))
        .concat(
          action.payload.oldFollowed.filter(
            (x) => !action.payload.followed.includes(x)
          )
        )[0];
      if (differenceId) {
        const sum =
          action.payload.followed > action.payload.oldFollowed ? 1 : -1;
        newCategories = state.categories.map((c) => {
          if (action.payload.followed.indexOf(c.id) > -1) {
            c.currentUserFollows = true;
          }
          return c.id === differenceId
            ? { ...c, totalFollowers: c.totalFollowers + sum }
            : c;
        });
      }
      return {
        ...state,
        categories: newCategories,
        followed: action.payload.followed,
      };
    }
    case "resetStatus":
      return {
        ...initialState,
      };
    default:
      throw new Error();
  }
};

const Categories = createContext({
  categoriesState: initialState,
  dispatch: () => void 0,
});

const CategoriesProvider = ({ children }) => {
  const [categoriesState, dispatch] = useReducer(reducer, initialState);
  return (
    <Categories.Provider
      value={{
        categoriesState,
        dispatch,
      }}
    >
      {children}
    </Categories.Provider>
  );
};

const useCategories = () => useContext(Categories);

export { CategoriesProvider, useCategories };
