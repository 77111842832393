import React, { useState } from "react";

import {
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  UncontrolledAlert,
  FormFeedback,
  ListGroup,
  ListGroupItem,
  CustomInput,
} from "reactstrap";

import Loader from "../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import InformationModal from "../../components/InformationModal";
import { useHistory } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import logo from "../../assets/images/logo-gaucho.png";
import CustomCheckbox from "../../components/CustomCheckbox";
import { commonApi } from "../../services";

const GENDERS = [
  {
    label: "Masculino",
    key: "MASCULINO",
  },
  {
    label: "Femenino",
    key: "FEMENINO",
  },
  {
    label: "Otro",
    key: "OTRO",
  },
];

const SignUpForm = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [catpchaError, setCatpchaError] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [acceptedTerms, setAcceptedTerms] = useState();
  const [localData, setLocalData] = useState({});

  const onDismiss = () => setError(false);

  const onSubmit = (event) => {
    event.preventDefault();
    if (!acceptedTerms) {
      return setInformationModal({
        isOpen: true,
        title: "Registro de Usuario",
        body: "Debes aceptar los términos y condiciones para registrarte.",
      });
    }
    setIsLoading(true);
    commonApi
      .createUser(localData)
      .then(() => {
        setIsLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Registro de Usuario",
          body: "Usuario creado con éxito",
          onClose: () => history.push("/auth/sign-in"),
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Registro de Usuario",
          body: err?.response?.data[0]?.msg,
        });
      });
  };

  const handleVerifyCaptcha = (token) => {
    commonApi.captchaVerify(token).then((result) => {
      if (!result?.success) {
        setCatpchaError(
          "Parece que eres un robot, intenta nuevamente refrescando la pagina."
        );
      }
    });
  };

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
      language="es"
    >
      <React.Fragment>
        <div className="d-flex flex-column justify-content-start flex-grow-1 p-1 p-md-2 p-lg-3 col-12">
          <div className="text-center mb-2 mb-md-4">
            <img
              className="col-12 px-0 width-200"
              src={logo}
              alt={"Red Gaucho"}
            ></img>
          </div>
          <div className="mt-1">
            <h4 className="mt-0 mb-1 mb-md-2 font-weight-500">
              Registro de Usuario
            </h4>
            <p className="text-muted mb-3">
              Ingresa tus datos para crear tu cuenta.
            </p>
            <Form onSubmit={onSubmit}>
              <FormGroup>
                <Label className="font-weight-500">
                  <span>Nombre</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  maxLength={50}
                  required={true}
                  name="firstName"
                  className="mb-1 form-control-lg form-control"
                  value={localData.firstName || ""}
                  placeholder="Ingresa tu nombre"
                  onChange={(event) =>
                    setLocalData({
                      ...localData,
                      firstName: event.currentTarget.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label className="font-weight-500">
                  <span>Apellido</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  maxLength={50}
                  required={true}
                  name="lastName"
                  className="mb-1 form-control-lg form-control"
                  value={localData.lastName || ""}
                  placeholder="Ingresa tu apellido"
                  onChange={(event) =>
                    setLocalData({
                      ...localData,
                      lastName: event.currentTarget.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label className="font-weight-500">
                  <span>Email</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  maxLength={50}
                  required={true}
                  name="email"
                  type="email"
                  className="mb-1 form-control-lg form-control"
                  value={localData.email || ""}
                  placeholder="Ingresa tu email"
                  onChange={(event) =>
                    setLocalData({
                      ...localData,
                      email: event.currentTarget.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label className="font-weight-500">
                  <span>Género</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
                <CustomInput
                  required
                  type="select"
                  name="genderSelect"
                  style={{ height: 48 }}
                  className="mb-1 form-control-lg form-control"
                  onChange={(event) =>
                    setLocalData({
                      ...localData,
                      gender: event.currentTarget.value,
                    })
                  }
                  value={localData.gender}
                >
                  <option value={""}>Selecciona tu género</option>
                  {GENDERS.map((gender) => (
                    <option key={gender.key} value={gender.key}>
                      {gender.label}
                    </option>
                  ))}
                </CustomInput>
              </FormGroup>
              <FormGroup>
                <Label className="font-weight-500">
                  <span>Contraseña</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  required={true}
                  maxLength={50}
                  type="password"
                  name="password"
                  className="mb-1 form-control-lg form-control"
                  value={localData.newPassword}
                  invalid={localData.newPasswordError}
                  placeholder="Ingresa tu contraseña"
                  onChange={(event) => {
                    const value = event.currentTarget.value;
                    setLocalData({
                      ...localData,
                      newPassword: value,
                      newPasswordError: value.length < 6,
                    });
                  }}
                />
                <FormFeedback>
                  La contraseña debe tener como minimo 6 caracteres.
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label className="font-weight-500">
                  <span>Repite Contraseña</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  required={true}
                  maxLength={50}
                  type="password"
                  name="repassword"
                  className={`mb-1 form-control-lg form-control`}
                  value={localData.repassword}
                  invalid={localData.repasswordError}
                  placeholder="Reingresa tu contraseña"
                  onChange={(event) => {
                    const value = event.currentTarget.value;
                    setLocalData({
                      ...localData,
                      repassword: value,
                      repasswordError: localData.newPassword !== value,
                    });
                  }}
                />
                <FormFeedback>
                  Las contraseñas ingresadas no coinciden.
                </FormFeedback>
              </FormGroup>
              <ListGroup>
                <ListGroupItem
                  className="d-flex justify-content-between align-items-center cursor-pointer"
                  onClick={() => setAcceptedTerms(!acceptedTerms)}
                >
                  <div>
                    Al registrarme estoy de acuerdo con los{" "}
                    <span
                      onClick={() =>
                        window.open(`${window.location.origin}/terms`, "_blank")
                      }
                      className="text-primary cursor-pointer font-weight-bold"
                    >
                      términos y condiciones
                    </span>
                  </div>
                  <div className="min-width-50 d-flex justify-content-end">
                    <CustomCheckbox checked={acceptedTerms} />
                  </div>
                </ListGroupItem>
              </ListGroup>
              <div className="text-center mt-3">
                <GoogleReCaptcha onVerify={handleVerifyCaptcha} />
                <UncontrolledAlert
                  className="alert-outline"
                  isOpen={!!error}
                  toggle={onDismiss}
                  color="danger"
                >
                  <div className="alert-icon d-flex align-items-center">
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                  </div>
                  <div className="alert-message text-left pr-4">
                    <small>{error}</small>
                  </div>
                </UncontrolledAlert>
                <UncontrolledAlert
                  className="alert-outline"
                  isOpen={!!catpchaError}
                  color="danger"
                >
                  <div className="alert-icon d-flex align-items-center">
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                  </div>
                  <div className="alert-message text-left pr-4">
                    <small>{catpchaError}</small>
                  </div>
                </UncontrolledAlert>
                {isLoading ? (
                  <div className="min-width-50 my-2">
                    <Loader size="sm" />
                  </div>
                ) : (
                  <>
                    <div className="form-group mb-0 text-center">
                      <Button
                        disabled={
                          catpchaError ||
                          !localData.newPassword ||
                          localData.newPasswordError ||
                          !localData.repassword ||
                          localData.repasswordError
                        }
                        color="primary"
                        type="submit"
                        size="lg"
                        className="btn-block py-1 d-flex align-items-center justify-content-center"
                      >
                        Registrarse
                      </Button>
                    </div>
                    <footer className="footer footer-alt pb-0 d-flex flex-column">
                      <p className="text-muted">
                        ¿Ya estás registrado?
                        <span
                          onClick={() => history.push("/auth/sign-in")}
                          className="text-success cursor-pointer ml-1"
                        >
                          Ingresa!
                        </span>
                      </p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.planagropecuario.org.uy"
                        className="text-success ml-1"
                      >
                        Instituto Plan Agropecuario
                      </a>
                      <span className="mt-2">{new Date().getFullYear()}</span>
                    </footer>
                  </>
                )}
              </div>
            </Form>
            {informationModal.isOpen ? (
              <InformationModal
                title={informationModal.title}
                body={informationModal.body}
                onClose={() =>
                  informationModal.onClose
                    ? informationModal.onClose()
                    : setInformationModal({
                        isOpen: false,
                        title: "",
                        body: "",
                      })
                }
              />
            ) : null}
          </div>
        </div>
      </React.Fragment>
    </GoogleReCaptchaProvider>
  );
};

export default SignUpForm;
