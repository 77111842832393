import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  UncontrolledAlert,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import Loader from "../Loader";
import { useHistory } from "react-router";
import { RefreshCw } from "react-feather";
import NotificationsModal from "../NotificationsModal";
import { commonApi } from "../../services";
import { useAuth, useNotifications } from "../../providers";

const NOTIFICATION_STATUS_UNREAD = 1;

const NotificationDropdown = () => {
  const { notificationsState, dispatchNotificationsChange } =
    useNotifications();
  const [authContext] = useAuth();
  const history = useHistory();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [notificationsModal, setNotificationsModal] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    commonApi
      .getUserNotifications(authContext.currentUser.id, {})
      .then((notifications) => {
        setIsLoading(false);
        dispatchNotificationsChange({
          type: "getNotifications",
          payload: notifications,
        });
      });
  }, [refresh, authContext.currentUser.id, dispatchNotificationsChange]);

  const onReadNotification = (event, id) => {
    setIsLoading(true);
    if (event) {
      event.stopPropagation();
    }
    commonApi.readNotification(id).then(() => {
      setIsLoading(false);
      dispatchNotificationsChange({
        type: "onReadNotification",
        payload: { id },
      });
    });
  };

  const onReadAll = (event) => {
    setIsLoading(true);
    event.stopPropagation();
    commonApi.readAllNotification().then(() => {
      setIsLoading(false);
      dispatchNotificationsChange({
        type: "onReadAllNotifications",
      });
    });
  };

  const onSeeAll = () => setNotificationsModal(true);

  const unReadNotifications = notificationsState?.notifications.filter(
    (n) => n.status === NOTIFICATION_STATUS_UNREAD
  );

  return (
    <>
      {notificationsModal ? (
        <NotificationsModal onClose={() => setNotificationsModal(false)} />
      ) : null}
      <Dropdown
        isOpen={dropdownOpen}
        toggle={() => {
          setDropdownOpen(!dropdownOpen);
        }}
      >
        <DropdownToggle
          data-toggle="dropdown"
          tag="button"
          className="nav-link dropdown-toggle arrow-none btn btn-link mx-0"
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
          }}
          aria-expanded={dropdownOpen}
        >
          <i className="mdi mdi-bell-outline noti-icon"></i>
          {unReadNotifications.length ? (
            <span className="noti-icon-badge"></span>
          ) : null}
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-animated dropdown-lg mt-1">
          <div
            onClick={() => {
              setDropdownOpen(!dropdownOpen);
            }}
          >
            <div className="dropdown-item noti-title">
              <h5 className="m-0 d-flex justify-content-between align-items-center">
                <span>Notificaciones</span>
                <small className="float-right">
                  <RefreshCw
                    className="cursor-pointer"
                    height={14}
                    onClick={(event) => {
                      setRefresh(!refresh);
                      event?.stopPropagation();
                    }}
                  />
                </small>
              </h5>
            </div>
            {isLoading ? (
              <div className="min-width-50">
                <Loader size="sm" />
              </div>
            ) : (
              <SimpleBar hidden={!dropdownOpen} className="text-center">
                {unReadNotifications
                  .sort((x, y) => x.id - y.id)
                  .slice(0, 5)
                  .map((item) => {
                    return (
                      <UncontrolledAlert
                        onClick={() => {
                          onReadNotification(null, item.id);
                          history.push(`/questions/${item.payload.questionId}`);
                        }}
                        color={"primary"}
                        className="alert-outline cursor-pointer mx-1"
                        key={item.id}
                        toggle={(event) => onReadNotification(event, item.id)}
                      >
                        <div className="alert-message text-left pl-1 pr-3 pt-0">
                          <small>
                            <b>{item.title}:</b>
                            <div
                              className="text-italic"
                              dangerouslySetInnerHTML={{
                                __html: item.body,
                              }}
                            ></div>
                          </small>
                        </div>
                      </UncontrolledAlert>
                    );
                  })}
                {!unReadNotifications.length ? (
                  <div className="small mb-2">No hay notificaciones nuevas</div>
                ) : null}
              </SimpleBar>
            )}
            <div className="d-flex justify-content-between">
              <small
                onClick={() => onSeeAll()}
                className="cursor-pointer dropdown-item text-center text-primary notify-item notify-all"
              >
                Ver todas
              </small>
              {unReadNotifications.length ? (
                <small
                  onClick={(event) => onReadAll(event)}
                  className="cursor-pointer dropdown-item text-center text-warning notify-item notify-all"
                >
                  Marcar todas como leídas
                </small>
              ) : null}
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default NotificationDropdown;
