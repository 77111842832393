import React, { useState } from "react";

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert,
} from "reactstrap";

import logo from "../../../assets/images/logo-gaucho.png";

import Loader from "../../../components/Loader";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import InformationModal from "../../../components/InformationModal";
import { authApi } from "../../../services";

export const ResetPasswordForm = () => {
  const history = useHistory();

  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onDismiss = () => setError(false);

  const resetPassword = (e) => {
    e.preventDefault();
    setError(false);
    setLoading(true);
    authApi
      .reset({
        email,
      })
      .then(() => {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Reseteo de contraseña",
          body: "Te llegará un mail con las instrucciones para resetear tu contraseña",
          onClose: () => history.push("/auth/sign-in"),
        });
      })
      .catch((err) => {
        setLoading(false);
        setError(
          typeof err.response?.data === "string"
            ? err.response.data
            : "Ha habido un error, intenta nuevamente."
        );
      });
    return false;
  };

  return (
    <React.Fragment>
      <div className="d-flex flex-column justify-content-center flex-grow-1 p-1 p-md-2 p-lg-3">
        <div className="text-center mb-2 mb-md-4">
          <img
            className="col-12 px-0 width-200"
            src={logo}
            alt={"Red Gaucho"}
          ></img>
        </div>
        <div className="mt-1">
          <h4 className="mt-0 mb-1 mb-md-2 font-weight-500">
            ¿Olvidaste tu contraseña?
          </h4>
          <p className="text-muted mb-4">
            Ingresa tu cédula de identidad para resetear tu contraseña.
          </p>
          <Form onSubmit={resetPassword}>
            <FormGroup>
              <Label className="font-weight-500">
                <span>Email</span>
                <span className="text-danger ml-1">*</span>
              </Label>
              <Input
                maxLength={50}
                required={true}
                name="email"
                onChange={(event) => setEmail(event.currentTarget.value)}
                className="mb-1 form-control-lg form-control"
                placeholder="Ingresa tu email"
              />
            </FormGroup>
            <div className="text-center mt-3">
              <UncontrolledAlert
                className="alert-outline"
                isOpen={!!error}
                toggle={onDismiss}
                color="danger"
              >
                <div className="alert-icon d-flex align-items-center">
                  <FontAwesomeIcon icon={faBell} fixedWidth />
                </div>
                <div className="alert-message text-left pr-4">
                  <small>{error}</small>
                </div>
              </UncontrolledAlert>
              {loading ? (
                <div className="min-width-50 my-2">
                  <Loader size="sm" />
                </div>
              ) : (
                <div className="form-group mb-0 text-center">
                  <Button
                    color="primary"
                    type="submit"
                    size="lg"
                    className="btn-block py-1 d-flex align-items-center justify-content-center"
                  >
                    Enviar
                  </Button>
                </div>
              )}
            </div>
          </Form>
          <footer className="footer footer-alt pb-0 d-flex flex-column">
            <p className="text-muted">
              ¿Ya recordaste tu contraseña?
              <span
                onClick={() => history.push("/auth/sign-in")}
                className="text-success cursor-pointer ml-1"
              >
                Ingresa!
              </span>
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.planagropecuario.org.uy"
              className="text-success ml-1"
            >
              Instituto Plan Agropecuario
            </a>
            <span className="mt-2">{new Date().getFullYear()}</span>
          </footer>
        </div>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </React.Fragment>
  );
};

export default ResetPasswordForm;
