const api_dev = "https://api.dev.redgaucho.com/";
const api_prod = "https://api.redgaucho.com/";
const web_dev = "https://dev.redgaucho.com/";
const web_prod = "https://redgaucho.com/";

let api_local = "http://localhost:3000/";

let apiURL = api_local;
let webURL = api_local;
let generalImagesBucket;
let generalImagesThumbnailBucket;
let profileImagesBucket;
let profileImagesThumbnailBucket;

if (process.env.REACT_APP_ENV === "production") {
  apiURL = api_prod;
  webURL = web_prod;
  generalImagesBucket = "general-images-regular-prod-rg";
  generalImagesThumbnailBucket = "general-images-thumbnail-prod-rg";
  profileImagesBucket = "profile-images-regular-prod-rg";
  profileImagesThumbnailBucket = "profile-images-thumbnail-prod-rg";
} else if (process.env.REACT_APP_ENV === "development") {
  apiURL = api_dev;
  webURL = web_dev;
  generalImagesBucket = "general-images-regular-dev-rg";
  generalImagesThumbnailBucket = "general-images-thumbnail-dev-rg";
  profileImagesBucket = "profile-images-regular-dev-rg";
  profileImagesThumbnailBucket = "profile-images-thumbnail-dev-rg";
} else {
  apiURL = api_local;
  webURL = api_local;
  generalImagesBucket = "general-images-regular-dev-rg";
  generalImagesThumbnailBucket = "general-images-thumbnail-dev-rg";
  profileImagesBucket = "profile-images-regular-dev-rg";
  profileImagesThumbnailBucket = "profile-images-thumbnail-dev-rg";
}

const config = {
  apiURL,
  webURL,
  generalImagesBucket,
  generalImagesThumbnailBucket,
  profileImagesBucket,
  profileImagesThumbnailBucket,
};

export default config;
