import React, { useEffect, useState } from "react";

import Wrapper from "../components/Wrapper";
import Main from "../components/Main";
import Content from "../components/Content";
import { Redirect, useLocation } from "react-router-dom";
import Loader from "../components/Loader";
import Topbar from "../components/topbar/Topbar";
import { authApi, commonApi } from "../services";
import {
  useAuth,
  useCategories,
  useNotifications,
  useProfile,
} from "../providers";
import { sockets } from "../utils/sockets";

const Customer = ({ children }) => {
  const [authContext, setAuthContext] = useAuth();
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const { categoriesState, dispatch: dispatchCategoriesChange } =
    useCategories();

  const { dispatchNotificationsChange } = useNotifications();
  const { dispatch: dispatchProfileChange } = useProfile();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!categoriesState?.categories?.length) {
      setIsLoading(true);
      commonApi.getCategories().then((categories) => {
        setIsLoading(false);
        dispatchCategoriesChange({
          type: "getCategoriesSuccess",
          payload: categories,
        });
      });
    }
  }, [dispatchCategoriesChange, categoriesState?.categories]);

  if (redirectToLogin) {
    dispatchCategoriesChange({
      type: "resetStatus",
    });
    dispatchNotificationsChange({
      type: "resetStatus",
    });
    dispatchProfileChange({
      type: "resetStatus",
    });
    return <Redirect to={`/auth/sign-in`} />;
  }

  if (!authContext.currentUser) {
    authApi.validate().then((result) => {
      if (!result) {
        return setRedirectToLogin(true);
      }
      setAuthContext({ currentUser: result });
      sockets.init(result.id, dispatchNotificationsChange);
    });
    return <Loader />;
  }

  if (
    authContext.currentUser &&
    categoriesState?.categories.length &&
    !categoriesState.categories.find((c) => c.currentUserFollows)
  ) {
    debugger;
    return <Redirect to={`/categories/select`} />;
  }

  const isRoute = (route) => location.pathname === route;

  return (
    <React.Fragment>
      <Wrapper className="overflow-x-hidden">
        <Main className="d-flex flex-column justify-content-between">
          {isLoading || !categoriesState?.categories?.length ? (
            <Loader />
          ) : (
            <div className="flex-grow-1 d-flex flex-column">
              <Topbar navCssClasses="topnav-navbar" />
              <Content marginTop={isRoute("/search") ? "-70px" : "0"}>
                {children}
              </Content>
            </div>
          )}
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default Customer;
