import React, { useReducer, createContext, useContext } from "react";

const MAX_PAGE_SIZE = 10;

const QUESTION_CATEGORY_ANY = "QUESTION_CATEGORY_ANY";

const CONTENT_TYPE_QUESTION = "QUESTION";
const CONTENT_TYPE_USER = "USER";
const CONTENT_TYPE_CATEGORY = "CATEGORY";

export const initialState = {
  page: 1,
  contentType: CONTENT_TYPE_QUESTION,
  questionCategory: QUESTION_CATEGORY_ANY,
  term: "",
};

const ACTIONS = {
  ON_SEARCH: "ON_SEARCH",
  CONTENT_TYPE_CHANGE: "CONTENT_TYPE_CHANGE",
  QUESTION_CATEGORY_CHANGE: "QUESTION_CATEGORY_CHANGE",
  REFRESH: "REFRESH",
  NEXT_PAGE: "NEXT_PAGE",
  GET_DATA_SUCCESS: "GET_DATA_SUCCESS",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.ON_SEARCH: {
      const { payload } = data;

      return {
        ...state,
        term: payload,
        page: 1,
      };
    }

    case ACTIONS.CONTENT_TYPE_CHANGE: {
      const { payload } = data;

      return {
        ...state,
        page: 1,
        contentType: payload,
      };
    }

    case ACTIONS.QUESTION_CATEGORY_CHANGE: {
      const { payload } = data;

      return {
        ...state,
        page: 1,
        questionCategory: payload,
      };
    }

    case ACTIONS.GET_DATA_SUCCESS: {
      const { payload } = data;

      switch (state.contentType) {
        case CONTENT_TYPE_QUESTION:
          return {
            ...state,
            questions: payload,
          };
        case CONTENT_TYPE_USER:
          return {
            ...state,
            users: payload,
          };
        case CONTENT_TYPE_CATEGORY:
          return {
            ...state,
            categories: payload,
          };
        default:
          return state;
      }
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        page: 1,
        refresh: !state.refresh,
      };

    case ACTIONS.NEXT_PAGE: {
      return {
        ...state,
        page: state.page + 1,
      };
    }

    default:
      throw new Error();
  }
};

const SearchContext = createContext(initialState);

const SearchProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <SearchContext.Provider value={stateAndDispatch}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);

export { SearchContext, SearchProvider, ACTIONS, MAX_PAGE_SIZE };
