import React from "react";
import { Row, Col } from "reactstrap";

import Feeds from "./feed/Feed";
import MyCategories from "./feed/MyCategories";
import TopCategories from "./feed/TopCategories";

const Home = () => (
  <React.Fragment>
    <Row className="mt-3">
      <Col lg={3} md={12} className="order-md-1 order-lg-1">
        <MyCategories />
      </Col>
      <Col lg={6} md={12} className="order-md-1 order-lg-1">
        <Feeds />
      </Col>
      <Col lg={3} md={12} className="order-md-1 order-lg-1">
        <TopCategories />
      </Col>
    </Row>
  </React.Fragment>
);

export default Home;
