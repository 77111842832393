import React, { useState } from "react";

import { FormGroup, Label, Input, ListGroup, ListGroupItem } from "reactstrap";
import { useEffect } from "react";
import { faCheckCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader";
import { useSearch } from "../../providers";
import { commonApi } from "../../services";

const QUESTION_CATEGORY_ANY = "QUESTION_CATEGORY_ANY";

const CONTENT_TYPE_QUESTION = "QUESTION";
const CONTENT_TYPE_USER = "USER";
const CONTENT_TYPE_CATEGORY = "CATEGORY";

const ON_CONTENT_TYPE_CHANGE = "CONTENT_TYPE_CHANGE";
const ON_QUESTION_CATEGORY_CHANGE = "QUESTION_CATEGORY_CHANGE";

const ResultsFilters = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [searchContext, setSearchContext] = useSearch();

  useEffect(() => {
    commonApi.getCategories().then((categories) => {
      setIsLoading(false);
      setCategories(categories);
    });
  }, []);

  const onTypeChange = (contentType) => {
    setSearchContext({
      action: ON_CONTENT_TYPE_CHANGE,
      payload: contentType,
    });
  };

  const onCategoryChange = (questionCategory) => {
    setSearchContext({
      action: ON_QUESTION_CATEGORY_CHANGE,
      payload: questionCategory,
    });
  };

  return (
    <>
      <h4 className="text-center my-3">Filtro de Resultados</h4>
      <ListGroup
        flush
        className="text-left border rounded col-12 col-md-10 offset-md-1 px-0 mt-4 mt-md-0"
      >
        <ListGroupItem
          className="py-1 border-bottom font-weight-bold text-body bg-light text-center"
          tag="div"
        >
          Tipo de Contenido
        </ListGroupItem>
        <ListGroupItem
          className="border-bottom py-1 d-flex justify-content-between align-items-center"
          tag="div"
        >
          <FormGroup check className="col-12 px-0">
            <Label
              check
              className={`${
                searchContext.contentType === CONTENT_TYPE_QUESTION
                  ? "text-primary border-primary"
                  : ""
              } cursor-pointer font-weight-300 px-2 py-1 from-check-label col-12`}
            >
              <Input
                required
                className="ml-0 opacity-0"
                type="radio"
                name="tipoContenidoMc"
                checked={searchContext.contentType === CONTENT_TYPE_QUESTION}
                onChange={() => onTypeChange(CONTENT_TYPE_QUESTION)}
              />
              <FontAwesomeIcon
                icon={
                  searchContext.contentType === CONTENT_TYPE_QUESTION
                    ? faCheckCircle
                    : faCircle
                }
                className="text-primary"
                size="lg"
              />
              <small className="ml-2">Preguntas</small>
            </Label>
          </FormGroup>
        </ListGroupItem>
        <ListGroupItem
          className="border-bottom py-1 d-flex justify-content-between align-items-center"
          tag="div"
        >
          <FormGroup check className="col-12 px-0">
            <Label
              check
              className={`${
                searchContext.contentType === CONTENT_TYPE_CATEGORY
                  ? "text-primary border-primary"
                  : ""
              } cursor-pointer font-weight-300 px-2 py-1 from-check-label col-12`}
            >
              <Input
                required
                className="ml-0 opacity-0"
                type="radio"
                name="tipoContenidoMc"
                checked={searchContext.contentType === CONTENT_TYPE_CATEGORY}
                onChange={() => onTypeChange(CONTENT_TYPE_CATEGORY)}
              />
              <FontAwesomeIcon
                icon={
                  searchContext.contentType === CONTENT_TYPE_CATEGORY
                    ? faCheckCircle
                    : faCircle
                }
                className="text-primary"
                size="lg"
              />
              <small className="ml-2">Temas de Interés</small>
            </Label>
          </FormGroup>
        </ListGroupItem>
        <ListGroupItem
          className="border-bottom py-1 d-flex justify-content-between align-items-center"
          tag="div"
        >
          <FormGroup check className="col-12 px-0">
            <Label
              check
              className={`${
                searchContext.contentType === CONTENT_TYPE_USER
                  ? "text-primary border-primary"
                  : ""
              } cursor-pointer font-weight-300 px-2 py-1 from-check-label col-12`}
            >
              <Input
                required
                className="ml-0 opacity-0"
                type="radio"
                name="tipoContenidoMc"
                checked={searchContext.contentType === CONTENT_TYPE_USER}
                onChange={() => onTypeChange(CONTENT_TYPE_USER)}
              />
              <FontAwesomeIcon
                icon={
                  searchContext.contentType === CONTENT_TYPE_USER
                    ? faCheckCircle
                    : faCircle
                }
                className="text-primary"
                size="lg"
              />
              <small className="ml-2">Usuarios</small>
            </Label>
          </FormGroup>
        </ListGroupItem>
      </ListGroup>
      {searchContext.contentType === CONTENT_TYPE_QUESTION ? (
        <ListGroup
          flush
          className="mt-3 text-left border rounded col-12 col-md-10 offset-md-1 px-0"
        >
          <ListGroupItem
            className="py-1 border-bottom font-weight-bold text-body bg-light text-center"
            tag="div"
          >
            Tema de Interés
          </ListGroupItem>
          <ListGroupItem
            className="border-bottom py-1 d-flex justify-content-between align-items-center"
            tag="div"
          >
            <FormGroup check className="col-12 px-0">
              <Label
                check
                className={`${
                  searchContext.questionCategory === QUESTION_CATEGORY_ANY
                    ? "text-primary border-primary"
                    : ""
                } cursor-pointer font-weight-300 px-2 py-1 from-check-label col-12`}
              >
                <Input
                  required
                  className="ml-0 opacity-0"
                  type="radio"
                  name="categoryRadio"
                  checked={
                    searchContext.questionCategory === QUESTION_CATEGORY_ANY
                  }
                  onChange={() => onCategoryChange(QUESTION_CATEGORY_ANY)}
                />
                <FontAwesomeIcon
                  icon={
                    searchContext.questionCategory === QUESTION_CATEGORY_ANY
                      ? faCheckCircle
                      : faCircle
                  }
                  className="text-primary"
                  size="lg"
                />
                <small className="ml-2">Cualquiera</small>
              </Label>
            </FormGroup>
          </ListGroupItem>
          {isLoading ? (
            <div className="min-width-50 my-2">
              <Loader size="sm" />
            </div>
          ) : (
            categories.map((category) => (
              <ListGroupItem
                className="border-bottom py-1 d-flex justify-content-between align-items-center"
                tag="div"
              >
                <FormGroup check className="col-12 px-0">
                  <Label
                    check
                    className={`${
                      searchContext.questionCategory === category.id
                        ? "text-primary border-primary"
                        : ""
                    } cursor-pointer font-weight-300 px-2 py-1 from-check-label col-12`}
                  >
                    <Input
                      required
                      className="ml-0 opacity-0"
                      type="radio"
                      name="categoryRadio"
                      checked={searchContext.questionCategory === category.id}
                      onChange={() => onCategoryChange(category.id)}
                    />
                    <FontAwesomeIcon
                      icon={
                        searchContext.questionCategory === category.id
                          ? faCheckCircle
                          : faCircle
                      }
                      className="text-primary"
                      size="lg"
                    />
                    <small className="ml-2">{category.name}</small>
                  </Label>
                </FormGroup>
              </ListGroupItem>
            ))
          )}
        </ListGroup>
      ) : null}
    </>
  );
};

export default ResultsFilters;
