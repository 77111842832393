import React from "react";

import { Col, Container, Row } from "reactstrap";

import Main from "../components/Main";

const Default = ({ children }) => (
  <React.Fragment>
    <Main className="h-100 w-100">
      <Container className="h-100">
        <Row className="h-100">
          <Col className="col-10 offset-1 mx-auto d-table h-100">
            <div className="d-flex align-items-center height-100">
              {children}
            </div>
          </Col>
        </Row>
      </Container>
    </Main>
  </React.Fragment>
);

export default Default;
