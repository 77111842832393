import React from "react";

const Content = ({ children, marginTop = "0" }) => (
  <div
    style={{ marginTop }}
    className="content col-12 flex-grow-1 d-flex flex-column"
  >
    {children}
  </div>
);

export default Content;
