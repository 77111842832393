import React, { useEffect, useState } from "react";

import { Row, Col, Button } from "reactstrap";

import ResultsFilters from "./ResultsFilters";
import CategoryResults from "./CategoryResults";
import QuestionResults from "./QuestionResults";

import UserResults from "./UserResults";

import Modal from "react-modal-slider";
import "react-modal-slider/lib/main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useSearch } from "../../providers";

const CONTENT_TYPE_QUESTION = "QUESTION";
const CONTENT_TYPE_USER = "USER";
const CONTENT_TYPE_CATEGORY = "CATEGORY";

const SearchResults = () => {
  const [searchContext] = useSearch();
  const [showFilterModal, setShowFilterModal] = useState(false);

  useEffect(() => {
    setShowFilterModal(false);
  }, [searchContext.term]);

  const toggleFilterModal = () => setShowFilterModal(!showFilterModal);

  return (
    <React.Fragment>
      <Row className="flex-grow-1">
        <Col className="d-none d-lg-block col-lg-4 col-12 my-lg-3 pb-lg-3 pb-2">
          <ResultsFilters />
        </Col>
        <Col className="col-lg-8 col-12 my-md-3 pb-3">
          <div className="d-flex justify-content-around mt-md-4 pt-md-1">
            <h4 className="text-center my-3">
              Resultados de búsqueda para "<i>{searchContext.term}</i>"
            </h4>
          </div>
          {searchContext.contentType === CONTENT_TYPE_QUESTION ? (
            <QuestionResults toggleFilterModal={toggleFilterModal} />
          ) : null}
          {searchContext.contentType === CONTENT_TYPE_USER ? (
            <UserResults toggleFilterModal={toggleFilterModal} />
          ) : null}
          {searchContext.contentType === CONTENT_TYPE_CATEGORY ? (
            <CategoryResults toggleFilterModal={toggleFilterModal} />
          ) : null}
        </Col>
      </Row>
      <Modal
        isOpen={showFilterModal}
        width={"60%"}
        directionFrom={"left"}
        contentLabel={"Filters Modal"}
        onRequestClose={() => setShowFilterModal(false)}
        setAppElement={"#root"}
        ariaHideApp={true}
        maxMediaWidth={480}
      >
        <div className="flex-grow-1 m-3 pt-5 pt-md-1 mt-5 overflow-y-auto hide-scroll-bar">
          <Button
            className="close pt-2"
            onClick={() => setShowFilterModal(false)}
          >
            <FontAwesomeIcon icon={faTimesCircle} size="sm" />
          </Button>
          <ResultsFilters />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default SearchResults;
