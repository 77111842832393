import React, { useReducer, createContext, useContext } from "react";

const initialState = {};

const itemsById = (items) => {
  return items.reduce((map, item) => {
    map[item.id] = { ...item };
    return map;
  }, {});
};

const defaultUserProfileState = {
  questions: {
    refresh: false,
    data: [],
    byId: {},
  },
  answers: {
    refresh: false,
    data: [],
    byId: {},
  },
  comments: {
    refresh: false,
    data: [],
    byId: {},
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "getProfileAnswers": {
      const { userId, page, totalPages } = action.payload;
      state[userId] = state[userId] ?? defaultUserProfileState;

      const byId = itemsById(action.payload.data);
      //we need to append the new "page" and not overwrite, given that is infinite scroll
      const answers =
        action.payload.page === 0
          ? action.payload.data
          : [...state[userId].answers.data, ...action.payload.data];

      return {
        ...state,
        [userId]: {
          ...state[userId],
          answers: {
            ...state[userId].answers,
            data: answers,
            byId,
            page,
            totalPages,
          },
        },
      };
    }
    case "getProfileQuestions": {
      const { userId, page, totalPages } = action.payload;
      state[userId] = state[userId] ?? defaultUserProfileState;

      const byId = itemsById(action.payload.data);
      //we need to append the new "page" and not overwrite, given that is infinite scroll
      const questions =
        action.payload.page === 0
          ? action.payload.data
          : [...state[userId].questions.data, ...action.payload.data];

      return {
        ...state,
        [userId]: {
          ...state[userId],
          questions: {
            ...state[userId].questions,
            data: questions,
            byId,
            page,
            totalPages,
          },
        },
      };
    }
    case "getProfileComments": {
      const { userId, page, totalPages } = action.payload;
      state[userId] = state[userId] ?? defaultUserProfileState;

      const byId = itemsById(action.payload.data);
      //we need to append the new "page" and not overwrite, given that is infinite scroll
      const comments =
        action.payload.page === 0
          ? action.payload.data
          : [...state[userId].comments.data, ...action.payload.data];

      return {
        ...state,
        [userId]: {
          ...state[userId],
          comments: {
            ...state[userId].comments,
            data: comments,
            byId,
            page,
            totalPages,
          },
        },
      };
    }
    case "refresh": {
      const { userId } = action.payload;
      switch (action.payload.type) {
        case "Answer":
          if (!state[userId]?.answers) {
            return { ...state };
          }
          return {
            ...state,
            [userId]: {
              ...state[userId],
              answers: {
                ...state[userId].answers,
                refresh: !state[userId].answers.refresh,
              },
            },
          };
        case "Question":
          if (!state[userId]?.questions) {
            return { ...state };
          }
          return {
            ...state,
            [userId]: {
              ...state[userId],
              questions: {
                ...state[userId].questions,
                refresh: !state[userId].questions.refresh,
              },
            },
          };
        case "Comment":
          if (!state[userId]?.comments) {
            return { ...state };
          }
          return {
            ...state,
            [userId]: {
              ...state[userId],
              comments: {
                ...state[userId].comments,
                refresh: !state[userId].comments.refresh,
              },
            },
          };
        default:
          return state;
      }
    }
    case "resetStatus":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const ProfileContext = createContext({
  state: initialState,
  dispatch: () => void 0,
});

const ProfileProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ProfileContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

const useProfile = () => useContext(ProfileContext);

export { ProfileProvider, useProfile };
