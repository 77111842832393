import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

import MyCategories from "./feed/MyCategories";
import TopCategories from "./feed/TopCategories";

import Loader from "../components/Loader";
import Question from "./feed/Question";
import { commonApi } from "../services";

const QuestionView = ({ match }) => {
  const { id } = match.params;

  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState();

  useEffect(() => {
    const uriTitle = question?.title.trim().replace(/\s+/g, "-").toLowerCase();
    if (uriTitle)
      window.history.replaceState(
        null,
        "",
        `/questions/${question?.id}-${uriTitle}`
      );
  }, [question]);

  useEffect(() => {
    setIsLoading(true);
    commonApi.getQuestion(id).then((question) => {
      setIsLoading(false);
      setQuestion(question);
    });
  }, [id]);

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="min-width-50 my-2">
          <Loader size="sm" />
        </div>
      ) : question ? (
        <Row className="mt-3">
          <Col lg={3} md={12} className="order-md-1 order-lg-1">
            <MyCategories />
          </Col>
          <Col lg={6} md={12} className="order-md-1 order-lg-1">
            <Card>
              <CardBody className="p-0 d-flex flex-column">
                <Question
                  readOnly={false}
                  defaultExpanded={true}
                  question={question}
                  key={question.id}
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg={3} md={12} className="order-md-1 order-lg-1">
            <TopCategories />
          </Col>
        </Row>
      ) : null}
    </React.Fragment>
  );
};

export default QuestionView;
