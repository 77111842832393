import React, { useState } from "react";

import { ListGroup, ListGroupItem, Button } from "reactstrap";
import { useEffect } from "react";
import Loader from "../../components/Loader";
import Question from "../feed/Question";
import InfiniteScroll from "react-infinite-scroll-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { commonApi } from "../../services";
import { useSearch } from "../../providers";

const PAGE_SIZE = 2;

const ACTION_NEXT_PAGE = "NEXT_PAGE";

const QUESTION_CATEGORY_ANY = "QUESTION_CATEGORY_ANY";

const QuestionResults = ({ toggleFilterModal }) => {
  const [searchContext, setSearchContext] = useSearch();
  const [items, setItems] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (searchContext?.term?.length) {
      setIsLoading(true);
      commonApi
        .getQuestions(
          {
            search: searchContext.term,
            page: searchContext.page - 1,
            pageSize: PAGE_SIZE,
            categoryId:
              searchContext.questionCategory !== QUESTION_CATEGORY_ANY
                ? searchContext.questionCategory
                : null,
          },
          true
        )
        .then((result) => {
          setItems((prevValue) => {
            const questions =
              searchContext.page === 1
                ? result.questions
                : [...(prevValue.questions || []), ...result.questions];
            return {
              ...result,
              questions,
            };
          });
          setIsLoading(false);
        });
    }
  }, [
    searchContext.term,
    searchContext.page,
    searchContext.questionCategory,
    setSearchContext,
  ]);

  return (
    <ListGroup
      flush
      className="text-left col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 px-0 results-list"
    >
      <ListGroupItem
        className="font-weight-bold text-body bg-light text-center border d-flex justify-content-between align-items-center"
        tag="div"
      >
        <div className="col-3"></div>
        <div className="col-3">
          {items.count || 0} Pregunta{items.count !== 1 && "s"}
        </div>
        <div className="col-3 d-flex justify-content-end">
          <Button className="d-block d-lg-none" onClick={toggleFilterModal}>
            <FontAwesomeIcon icon={faFilter} />
          </Button>
        </div>
      </ListGroupItem>
      <InfiniteScroll
        dataLength={items?.questions?.length || 0} //This is important field to render the next data
        next={() =>
          setSearchContext({
            action: ACTION_NEXT_PAGE,
          })
        }
        hasMore={items.page < items.totalPages}
        loader={<h4 className="mb-3 text-center">Cargando..</h4>}
        endMessage={
          <ListGroupItem
            className={`pb-2 border-left border-bottom border-right text-center ${
              items.count ? "border-top" : ""
            }`}
            tag="div"
          >
            <span>
              {items.count
                ? "No hay más preguntas para mostrar"
                : "No hay resultados"}
            </span>
          </ListGroupItem>
        }
        scrollableTarget="wrapper"
      >
        {items.questions?.map((question) => (
          <Question
            readOnly={false}
            defaultExpanded={false}
            question={question}
            key={`${question.id}-${question.updatedAt}`}
          />
        ))}
      </InfiniteScroll>
      {isLoading ? (
        <div className="min-width-50 my-2">
          <Loader size="sm" />
        </div>
      ) : null}
    </ListGroup>
  );
};

export default QuestionResults;
