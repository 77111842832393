import React, { useState } from "react";

import Loader from "../Loader";
import Question from "../../pages/feed/Question";

import InfiniteScroll from "react-infinite-scroll-component";
import { Card, CardBody } from "reactstrap";
import { useEffect } from "react";
import { usePaginatedResource } from "../../utils/hooks";

const UserAnswers = ({ answersData, userId }) => {
  const [isLoading, fetchMore] = usePaginatedResource({
    userId,
    type: "Answer",
    pageSize: 5,
  });
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    setAnswers(answersData.data || []);
  }, [answersData.data]);

  const questions = answers
    .filter((a) => a.question)
    .reduce((p, c) => {
      p[c.questionId] = c.question.title || "Sin Título";
      return p;
    }, {});

  return (
    <>
      <InfiniteScroll
        className="results-list"
        dataLength={answers.length} //This is important field to render the next data
        next={fetchMore}
        hasMore={answersData.page < answersData.totalPages}
        loader={<h4 className="mb-3 text-center">Cargando..</h4>}
        endMessage={
          <Card className="mb-0 border">
            <CardBody className="text-center">
              <span>No hay más respuestas para mostrar</span>
            </CardBody>
          </Card>
        }
        scrollableTarget="wrapper"
      >
        {Object.keys(questions).map((questionId) => (
          <Question
            readOnly={true}
            defaultExpanded={true}
            question={{ id: questionId, title: questions[questionId] }}
            key={questionId}
            answerUserId={parseInt(userId)}
          />
        ))}
      </InfiniteScroll>
      {isLoading ? (
        <div className="min-width-50 my-2">
          <Loader size="sm" />
        </div>
      ) : null}
    </>
  );
};

export default UserAnswers;
