// Auth
import SignUp from "../pages/auth/SignUpForm";
import SignIn from "../pages/auth/signIn/SignIn";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import Home from "../pages/Home";
import Profile from "../pages/Profile";
import Category from "../pages/Category";
import Settings from "../pages/Settings";
import QuestionView from "../pages/Question";
import Privacy from "../pages/Privacy";
import Categories from "../pages/Categories";
import SearchResults from "../pages/search/SearchResults";
import ResetPassword from "../pages/auth/resetPassword/ResetPasswordForm";
import NewPassword from "../pages/auth/resetPassword/NewPasswordForm";
import Terms from "../pages/Terms";

export const customerRoutes = [
  {
    path: "/feed",
    name: "Inicio",
    component: Home,
  },
  {
    path: "/search",
    name: "Búsqueda",
    component: SearchResults,
  },
  {
    path: "/users/:id",
    name: "Perfil",
    component: Profile,
  },
  {
    path: "/settings",
    name: "Configuración",
    component: Settings,
  },
  {
    path: "/categories/:id",
    name: "Categoría",
    component: Category,
  },
  {
    path: "/categories",
    name: "Categorías",
    component: Categories,
  },
  {
    path: "/questions/:id",
    name: "Pregunta",
    component: QuestionView,
  },
];

export const authRoutes = [
  {
    path: "/auth/sign-in",
    name: "Ingresar",
    component: SignIn,
  },
  {
    path: "/auth/sign-up",
    name: "Registro",
    component: SignUp,
  },
  {
    path: "/auth/reset-password",
    name: "Resetear contraseña",
    component: ResetPassword,
  },
  {
    path: "/auth/reset-password/:token",
    name: "Resetear contraseña",
    component: NewPassword,
  },
  {
    path: "/auth/404",
    name: "404 Page",
    component: Page404,
  },
  {
    path: "/auth/500",
    name: "500 Page",
    component: Page500,
  },
];

export const publicRoutes = [
  {
    path: "/privacy",
    name: "Política de Privacidad",
    component: Privacy,
  },
  {
    path: "/terms",
    name: "Términos y Condiciones",
    component: Terms,
  },
];
