import React from "react";

import Wrapper from "../components/Wrapper";
import Main from "../components/Main";
import Content from "../components/Content";
import Footer from "../components/Footer";

const Public = ({ children }) => {
  return (
    <React.Fragment>
      <Wrapper className="overflow-x-hidden">
        <Main className="d-flex flex-column justify-content-between">
          <div>
            <Content>{children}</Content>
          </div>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default Public;
