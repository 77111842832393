import React from "react";
import { Row, Card, CardBody } from "reactstrap";

const Settings = () => {
  return (
    <React.Fragment>
      <Row>
        <Card className="col-12">
          <CardBody>Configuración</CardBody>
        </Card>
      </Row>
    </React.Fragment>
  );
};

export default Settings;
