import { useCallback, useEffect, useState } from "react";
import { useCategories, useProfile } from "../providers";
import { commonApi } from "../services";

function usePaginatedResource(props) {
  const { categoryId = -1, userId = -1, type, pageSize } = props;
  const [page, setPage] = useState(0);
  const { state, dispatch: dispatchProfileAction } = useProfile();
  const { dispatch: dispatchCategoryAction } = useCategories();

  // default this to true to kick the initial effect hook to
  // fetch the first page

  const [shouldFetch, setShouldFetch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isListEnd, setIsListEnd] = useState(false);

  // return this function for Flatlist to call onEndReached
  const fetchMore = useCallback(() => {
    setShouldFetch(true);
  }, [
    state[userId]?.answers?.refresh,
    state[userId]?.questions?.refresh,
    state[userId]?.comments?.refresh,
  ]);

  useEffect(() => {
    setPage(0);
  }, [
    state[userId]?.answers?.refresh,
    state[userId]?.questions?.refresh,
    state[userId]?.comments?.refresh,
  ]);

  useEffect(
    () => {
      // prevent fetching for other state changes
      if (!shouldFetch || isListEnd) {
        return;
      }

      const fetch = async () => {
        setIsLoading(true);
        switch (type) {
          case "Answer":
            {
              const response = await commonApi.getUserAnswers({
                userId,
                page,
                pageSize,
              });

              // set the should fetch call to false to prevent fetching on page number update
              setShouldFetch(false);
              dispatchProfileAction({
                type: "getProfileAnswers",
                payload: {
                  userId,
                  page,
                  count: pageSize,
                  totalPages: response.totalPages,
                  data: response.answers,
                },
              });

              if (response.answers.length > 0) {
                //increment page for the next call
                setPage(page + 1);
              } else {
                setIsListEnd(true);
              }
            }

            break;

          case "Comment":
            {
              const response = await commonApi.getUserComments({
                userId,
                page,
                pageSize,
              });

              // set the should fetch call to false to prevent fetching on page number update
              setShouldFetch(false);
              dispatchProfileAction({
                type: "getProfileComments",
                payload: {
                  userId,
                  page,
                  count: pageSize,
                  totalPages: response.totalPages,
                  data: response.data,
                },
              });

              if (response.data.length > 0) {
                //increment page for the next call
                setPage(page + 1);
              } else {
                setIsListEnd(true);
              }
            }

            break;

          case "Question":
            {
              const response = await commonApi.getUserQuestions({
                userId,
                page,
                pageSize,
              });

              // set the should fetch call to false to prevent fetching on page number update
              setShouldFetch(false);
              dispatchProfileAction({
                type: "getProfileQuestions",
                payload: {
                  userId,
                  page,
                  count: pageSize,
                  totalPages: response.totalPages,
                  data: response.questions,
                },
              });

              if (response.questions.length > 0) {
                //increment page for the next call
                setPage(page + 1);
              } else {
                setIsListEnd(true);
              }
            }

            break;

          case "CategoryQuestions":
            {
              const response = await commonApi.getCategoryQuestions({
                categoryId,
                page,
                pageSize,
              });

              // set the should fetch call to false to prevent fetching on page number update
              setShouldFetch(false);
              dispatchCategoryAction({
                type: "getCategoryQuestionsSuccess",
                payload: {
                  categoryId,
                  page,
                  count: pageSize,
                  totalPages: response.totalPages,
                  data: response.questions,
                },
              });

              if (response.questions.length > 0) {
                //increment page for the next call
                setPage(page + 1);
              } else {
                setIsListEnd(true);
              }
            }

            break;

          default:
            break;
        }

        setIsLoading(false);
      };

      fetch();
    },
    // prevent fetching for other state changes
    [page, shouldFetch]
  );

  return [isLoading, fetchMore, page];
}

export { usePaginatedResource };
