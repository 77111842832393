import socketIOClient from "socket.io-client";
import config from "../config/config";

export const sockets = {
  init: (userId, dispatchNotificationsChange) => {
    const socket = socketIOClient(config.apiURL, {
      transports: ["websocket"],
      path: "/socket.io/",
    });

    socket.emit("signup", userId);

    socket.on("signupSuccess", () => {
      dispatchNotificationsChange({
        type: "initNotifications",
        payload: socket,
      });
    });

    socket.on("newNotification", (data) => {
      dispatchNotificationsChange({ type: "onNewNotification", payload: data });
    });

    socket.on("disconnect", (data) => {
      console.log("disconnected", data);
      dispatchNotificationsChange({ type: "disconnectNotifications" });
    });

    socket.on("reconnect", () => {
      socket.emit("signup", userId);
    });
  },
};
