import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import PublicLayout from "../layouts/Public";
import CustomerLayout from "../layouts/Customer";
import AuthLayout from "../layouts/Auth";
import DefaultLayout from "../layouts/Default";
import Page404 from "../pages/auth/Page404";

import ScrollToTop from "../components/ScrollToTop";
import { authRoutes, customerRoutes, publicRoutes } from ".";
import Page500 from "../pages/auth/Page500";
import CategoriesInitial from "../pages/CategoriesInital";

const Routes = () => {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route
            path={"/categories/select"}
            exact
            render={() => <CategoriesInitial />}
          />
          {authRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact
              render={(props) => (
                <AuthLayout>
                  <route.component {...props} />
                </AuthLayout>
              )}
            />
          ))}
          {customerRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact
              render={(props) => (
                <CustomerLayout>
                  <route.component {...props} />
                </CustomerLayout>
              )}
            />
          ))}
          {publicRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact
              render={(props) => (
                <PublicLayout>
                  <route.component {...props} />
                </PublicLayout>
              )}
            />
          ))}
          <Route path={"/"} exact render={() => <Redirect to="/feed" />} />
          <Route
            path={"/error/500"}
            exact
            render={() => (
              <DefaultLayout>
                <Page500 />
              </DefaultLayout>
            )}
          />
          <Route
            path={"/error/404"}
            exact
            render={() => (
              <DefaultLayout>
                <Page404 />
              </DefaultLayout>
            )}
          />
          <Route
            render={() => (
              <DefaultLayout>
                <Page404 />
              </DefaultLayout>
            )}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
