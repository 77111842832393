import React, { useState } from "react";

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert,
} from "reactstrap";

import logo from "../../../assets/images/logo-gaucho.png";

import Loader from "../../../components/Loader";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InformationModal from "../../../components/InformationModal";
import { useHistory } from "react-router-dom";
import { useAuth, useNotifications } from "../../../providers";
import { authApi } from "../../../services";
import { sockets } from "../../../utils/sockets";

export const LoginForm = () => {
  const [, setAuthContext] = useAuth();
  const { dispatchNotificationsChange } = useNotifications();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });
  const history = useHistory();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onDismiss = () => setError(false);

  const doLogin = (e) => {
    e.preventDefault();
    setError(false);
    setLoading(true);
    authApi
      .login({
        email,
        password,
      })
      .then((result) => {
        setLoading(false);
        if (!result) {
          return null;
        }
        setAuthContext({ currentUser: result });
        sockets.init(result.id, dispatchNotificationsChange);
      })
      .catch((err) => {
        setLoading(false);
        setError(
          typeof err.response?.data === "string"
            ? err.response.data
            : "Ha habido un error, intenta nuevamente."
        );
      });
    return false;
  };

  return (
    <React.Fragment>
      <div className="d-flex flex-column justify-content-center flex-grow-1 p-1 p-md-2 p-lg-3">
        <div className="text-center mb-2 mb-md-4">
          <img
            className="col-12 px-0 width-200"
            src={logo}
            alt={"Red Gaucho"}
          ></img>
        </div>
        <div className="mt-1">
          <h4 className="mt-0 mb-1 mb-md-2 font-weight-500">Entrar</h4>
          <p className="text-muted mb-4">
            Ingresa tu email y contraseña para acceder a tu cuenta.
          </p>
          <Form onSubmit={doLogin}>
            <FormGroup>
              <Label className="font-weight-500">Email</Label>
              <Input
                maxLength={50}
                required={true}
                name="email"
                type="email"
                className="mb-1 form-control-lg form-control"
                placeholder="Ingresa tu email"
                onChange={(event) => setEmail(event.currentTarget.value.trim())}
              />
            </FormGroup>
            <FormGroup>
              <Label className="font-weight-500">Contraseña</Label>
              <Input
                required={true}
                className="form-control-lg form-control"
                maxLength={50}
                type="password"
                name="password"
                placeholder="Ingresa tu contraseña"
                onChange={(event) => setPassword(event.currentTarget.value)}
              />
              <div className="mt-1 d-flex justify-content-between col-12 px-0">
                <span
                  onClick={() => history.push("/auth/reset-password")}
                  className="text-success cursor-pointer ml-1"
                >
                  ¿Olvidaste tu contraseña?
                </span>
              </div>
            </FormGroup>
            <div className="text-center mt-3">
              <UncontrolledAlert
                className="alert-outline"
                isOpen={!!error}
                toggle={onDismiss}
                color="danger"
              >
                <div className="alert-icon d-flex align-items-center">
                  <FontAwesomeIcon icon={faBell} fixedWidth />
                </div>
                <div className="alert-message text-left pr-4">
                  <small>{error}</small>
                </div>
              </UncontrolledAlert>
              {loading ? (
                <div className="min-width-50 my-2">
                  <Loader size="sm" />
                </div>
              ) : (
                <div className="form-group mb-0 text-center">
                  <Button
                    color="primary"
                    type="submit"
                    size="lg"
                    className="btn-block py-1 d-flex align-items-center justify-content-center"
                  >
                    Ingresar
                  </Button>
                </div>
              )}
            </div>
          </Form>
          <footer className="footer footer-alt pb-0 d-flex flex-column">
            <p className="text-muted">
              ¿Aún no eres usuario?
              <span
                onClick={() => history.push("/auth/sign-up")}
                className="text-success cursor-pointer ml-1"
              >
                Regístrate!
              </span>
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.planagropecuario.org.uy"
              className="text-success ml-1"
            >
              Instituto Plan Agropecuario
            </a>
            <span className="mt-2">{new Date().getFullYear()}</span>
          </footer>
          {informationModal.isOpen ? (
            <InformationModal
              title={informationModal.title}
              body={informationModal.body}
              onClose={() =>
                informationModal.onClose
                  ? informationModal.onClose()
                  : setInformationModal({ isOpen: false, title: "", body: "" })
              }
            />
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};
