import React, { useEffect, useRef, useState } from "react";

import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Media,
  Badge,
} from "reactstrap";

import LightGallery from "lightgallery/react";
import lgThumbnail from "lightgallery/plugins/thumbnail";

import avatar from "../../assets/images/placeholder-3.png";

import moment from "moment";

import Answer from "./Answer";
import Loader from "../../components/Loader";
import UserAnswer from "./UserAnswer";
import ConfirmationModal from "../../components/ConfirmationModal";
import { useHistory } from "react-router-dom";

import TimeAgo from "react-timeago";
import esStrings from "react-timeago/lib/language-strings/es";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import QuestionModal from "../../components/customer/QuestionModal";

import ReportModal from "../../components/customer/ReportModal";

import useElementSize from "../../hooks/useElementSize";

import { useWindowWidth } from "@react-hook/window-size";
import { useAuth, useProfile, useQuestions } from "../../providers";
import { commonApi } from "../../services";

moment.locale("es-es");

const MODE_QUESTION_ANSWER = 1;
const MAX_QUESTION_HEIGHT = 400;
const REPORT_TYPE_QUESTION = "REPORT_TYPE_QUESTION";

const createMarkup = (text, collapsible = false) => {
  return { __html: `${text}${collapsible ? `<div class="faded"></div>` : ""}` };
};

const formatter = buildFormatter(esStrings);

const Question = ({
  question,
  defaultExpanded,
  readOnly,
  answerUserId = null,
  commentUserId = null,
}) => {
  const history = useHistory();
  const [authContext] = useAuth();
  const { dispatchQuestionsChange } = useQuestions();
  const { dispatch } = useProfile();
  const questionRef = useRef(null);
  const { height } = useElementSize(questionRef);
  const windowWidth = useWindowWidth();

  const [expanded, setExpanded] = useState(defaultExpanded);
  const [editQuestionModal, setEditQuestionModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [isAnswersLoading, setIsAnswersLoading] = useState(false);
  const [isAnswerLoading, setIsAnswerLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [completeQuestion, setCompleteQuestion] = useState(question);
  const [collapsedQuestion, setCollapsedQuestion] = useState(true);

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: () => {},
    onClose: () => {},
    title: "",
    body: "",
    confirmColor: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const questionHasOverflow =
    height > Math.min(MAX_QUESTION_HEIGHT, windowWidth / 2);

  useEffect(() => {
    if (expanded) {
      setIsAnswersLoading(true);
      commonApi.getQuestion(completeQuestion.id).then((result) => {
        setIsAnswersLoading(false);
        const answers = result.answers
          .filter((a) => (answerUserId ? a.user.id === answerUserId : true))
          .map((answer) => {
            const comments = answer.comments.filter((c) =>
              commentUserId ? c.user.id === commentUserId : true
            );
            return commentUserId && !comments?.length
              ? null
              : { ...answer, comments };
          })
          .filter(Boolean);
        setCompleteQuestion({
          ...result,
          answers,
          answersCount: answers.length,
        });
      });
    }
  }, [refresh, expanded, completeQuestion.id, answerUserId, commentUserId]);

  const onAnswerSubmit = (event, content, images) => {
    event.preventDefault();
    setIsAnswerLoading(true);
    commonApi
      .saveAnswer(completeQuestion.id, {
        content,
        images,
      })
      .then(() => {
        setIsAnswerLoading(false);
        setExpanded(true);
        setRefresh(!refresh);
      });
  };

  const onEdit = () => setEditQuestionModal(true);

  const onReport = () => setReportModal(true);

  const onDelete = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        setIsLoading(true);
        commonApi.deleteQuestion(completeQuestion.id).then(() => {
          setConfirmationModal(initConfirmationModal);
          dispatchQuestionsChange({
            type: "refreshQuestions",
          });
          dispatch({
            type: "refresh",
            payload: { userId: authContext.currentUser?.id, type: "Question" },
          });
          setIsLoading(false);
        });
        return;
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Borrar Pregunta",
      body: "¿Confirmas que deseas borrar la pregunta?",
      confirmColor: "danger",
    });
  };

  const onDeleteAnswer = (index) => {
    let answers = [...completeQuestion.answers];
    answers.splice(index, 1);
    const answersCount = completeQuestion.answersCount - 1;
    setCompleteQuestion({
      ...completeQuestion,
      answers,
      answersCount,
    });
  };

  const date =
    completeQuestion.updatedAt !== completeQuestion.createdAt
      ? completeQuestion.updatedAt
      : completeQuestion.createdAt;

  return (
    <Card className="border">
      {editQuestionModal ? (
        <QuestionModal
          onSubmit={({ title, content, categoriesIds, images }) => {
            setEditQuestionModal(false);
            setIsLoading(true);
            commonApi
              .updateQuestion(completeQuestion.id, {
                title,
                content,
                categoriesIds,
                images,
              })
              .then((updatedQuestion) => {
                setIsLoading(false);
                setCompleteQuestion(updatedQuestion);
                dispatchQuestionsChange({
                  type: "refreshQuestions",
                });
                dispatch({
                  type: "refresh",
                  payload: {
                    userId: authContext.currentUser?.id,
                    type: "Question",
                  },
                });
              });
          }}
          onClose={() => setEditQuestionModal(false)}
          question={completeQuestion}
        />
      ) : null}
      <CardBody className="pb-2">
        <Media>
          <div
            className="cursor-pointer mr-2 rounded-circle"
            style={{
              backgroundPosition: "center",
              backgroundImage: `url(${completeQuestion.user?.image || avatar})`,
              width: "40px",
              height: "40px",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              cursor: "pointer",
            }}
            onClick={() => history.push(`/users/${completeQuestion.user?.id}`)}
          ></div>
          <Media body className="relative">
            {completeQuestion.user?.id === authContext.currentUser?.id ? (
              isLoading ? (
                <div className="min-width-50 float-right">
                  <Loader size="sm" align="end" />
                </div>
              ) : (
                <UncontrolledDropdown
                  style={{ position: "absolute", right: "0", top: "-8px" }}
                >
                  <DropdownToggle
                    tag="button"
                    className="btn btn-sm btn-link no-arrow card-drop p-0"
                  >
                    <i className="mdi mdi-dots-horizontal"></i>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={onEdit}>Editar</DropdownItem>
                    <DropdownItem onClick={onDelete}>Borrar</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )
            ) : isLoading ? (
              <div className="min-width-50 my-2">
                <Loader size="sm" />
              </div>
            ) : (
              <UncontrolledDropdown
                style={{ position: "absolute", right: "0", top: "-8px" }}
              >
                <DropdownToggle
                  tag="button"
                  className="btn btn-sm btn-link no-arrow card-drop p-0"
                >
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={onReport}>Reportar</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
            <h5 className="question-user-name m-0">
              <span
                onClick={() =>
                  history.push(`/users/${completeQuestion.user?.id}`)
                }
                className="cursor-pointer"
              >
                {completeQuestion.user
                  ? `${completeQuestion.user?.firstName} ${completeQuestion.user?.lastName}`
                  : ""}
              </span>
              <small className="text-muted">
                <span className="mx-1"> · </span>
                <TimeAgo date={date} formatter={formatter} />
              </small>
            </h5>
            <p className="text-muted mt-1 col-12 px-0 d-flex align-items-center">
              <small className="flex-grow-1">
                {completeQuestion.categories?.map((category, index) => (
                  <span key={category.id}>
                    <span hidden={index === 0} className="mx-1">
                      {" "}
                      ·{" "}
                    </span>
                    <span
                      onClick={() => history.push(`/categories/${category.id}`)}
                      className="white-space-no-wrap cursor-pointer px-1 border rounded bg-light"
                    >
                      {category.name}
                    </span>
                  </span>
                )) || ""}
              </small>
            </p>
          </Media>
        </Media>
        <div className="border-top border-bottom">
          {completeQuestion.title?.length ? (
            <h5
              onClick={() => history.push(`/questions/${completeQuestion.id}`)}
              className="question-title py-1 my-1 font-weight-500 cursor-pointer"
              dangerouslySetInnerHTML={createMarkup(completeQuestion.title)}
            ></h5>
          ) : null}
          {completeQuestion.content ? (
            <div className="relative" ref={questionRef}>
              {completeQuestion.content?.length ? (
                <div
                  style={{
                    maxHeight:
                      questionHasOverflow && collapsedQuestion
                        ? Math.min(MAX_QUESTION_HEIGHT, windowWidth / 2)
                        : "100%",
                  }}
                  className={`question-content ${
                    collapsedQuestion ? "collapsed" : ""
                  } ${completeQuestion.title?.length ? "pb-1" : "pt-1"}`}
                  dangerouslySetInnerHTML={createMarkup(
                    completeQuestion.content,
                    questionHasOverflow && collapsedQuestion
                  )}
                ></div>
              ) : null}
              <div className="relative mb-1 d-flex flex-column align-items-end">
                {questionHasOverflow ? (
                  collapsedQuestion ? (
                    <Badge
                      className="collapse-q cursor-pointer"
                      onClick={() => setCollapsedQuestion(false)}
                      color={"secondary"}
                      size={18}
                      pill
                    >
                      Ver más
                    </Badge>
                  ) : (
                    <Badge
                      className="collapse-q cursor-pointer"
                      onClick={() => setCollapsedQuestion(true)}
                      color={"secondary"}
                      size={18}
                      pill
                    >
                      Ver menos
                    </Badge>
                  )
                ) : null}
              </div>
            </div>
          ) : null}
          {question.images?.length ? (
            <LightGallery
              licenseKey={process.env.REACT_APP_LIGHT_GALLERY_KEY}
              elementClassNames={`d-flex flex-${
                question.images.length === 2 ? "row" : "column"
              } flex-wrap my-2 light-gallery light-gallery-question rounded overflow-hidden`}
              thumbMargin={2}
              mode="lg-fade"
              speed={500}
              plugins={[lgThumbnail]}
            >
              {question.images.map((image, i) => (
                <a
                  key={i}
                  href={image}
                  className="flex-grow-1 min-width-50-percent min-height-50-percent"
                >
                  <div
                    className="rounded border border-white"
                    style={{
                      backgroundPosition: "center",
                      backgroundImage: `url(${image})`,
                      height: "100%",
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <img
                    alt={`QuestionImage-${i}`}
                    src={image}
                    className="d-none"
                  />
                </a>
              ))}
            </LightGallery>
          ) : null}
        </div>
        <div
          className="my-1 d-flex justify-content-between flex-shrink-0"
          onClick={() =>
            completeQuestion.answersCount ? setExpanded(!expanded) : null
          }
        >
          <span className="btn btn-sm btn-link text-muted pl-0 flex-shrink-0">
            <i className="uil uil-comments-alt mr-1"></i>
            {completeQuestion.answersCount}{" "}
            {completeQuestion.answersCount === 1 ? "Respuesta" : "Respuestas"}
          </span>
          {isAnswersLoading ? <Loader size="sm" align="end" /> : null}
        </div>
        {!readOnly ? (
          <div className="mt-0 p-2 bg-light rounded mb-2 border">
            <UserAnswer
              parent={completeQuestion.id}
              images={[]}
              value={null}
              mode={MODE_QUESTION_ANSWER}
              onSubmit={onAnswerSubmit}
            />
          </div>
        ) : null}
        {expanded ? (
          isAnswerLoading ? (
            <div className="min-width-50 my-2">
              <Loader size="sm" />
            </div>
          ) : completeQuestion?.answers?.length ? (
            <div className="mt-0 p-2 bg-light rounded mb-2 border">
              <div className="mt-n1">
                {completeQuestion.answers.map((answer, index) => (
                  <Answer
                    onRefresh={() => setRefresh(!refresh)}
                    readOnly={readOnly}
                    onDeleted={() => onDeleteAnswer(index)}
                    key={answer.id}
                    answer={answer}
                  />
                ))}
              </div>
            </div>
          ) : null
        ) : null}
      </CardBody>
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
      {reportModal ? (
        <ReportModal
          onClose={() => setReportModal(false)}
          onSubmit={() => {
            setReportModal(false);
            history.go(0);
          }}
          type={REPORT_TYPE_QUESTION}
          contentId={completeQuestion.id}
        />
      ) : null}
    </Card>
  );
};

export default Question;
