import React from "react";
import { Row, Card, CardBody } from "reactstrap";
import logo from "../assets/images/logo-gaucho.png";

const Terms = () => {
  return (
    <React.Fragment>
      <Row>
        <Card className="col-12">
          <CardBody className="text-justify">
            <div className="text-center mb-3">
              <img
                className="col-12 px-0 width-200"
                src={logo}
                alt={"Red Gaucho"}
              />
            </div>
            <h3>TÉRMINOS Y CONDICIONES GENERALES DE USO</h3>
            <p>
              Aquí se establecen los términos y condiciones generales de uso que
              regulan la utilización de redgaucho.com. Le pedimos que lea
              atentamente los siguientes términos y condiciones de uso del sitio
              web y de la aplicación, y en caso de no estar de acuerdo con los
              mismos, les solicitamos que no utilice el sitio web redgaucho.com
              en ninguna de las formas en que la misma se encuentra habilitada.
              Se requiere que los usuarios acepten los presentes términos (EULA)
              y con ello aceptan que no hay tolerancia para contenido objetable
              o usuarios abusivos.
            </p>

            <strong>OBJETO</strong>
            <p>
              redgaucho.com es un espacio de intercambio, que permite al Usuario
              interactuar con otros Usuarios a fin de compartir experiencias y
              conocimientos sobre temas vinculados al agro, dentro de los
              tópicos que disponga redgaucho.com y conforme a lo establecido en
              los presentes términos y condiciones.
            </p>

            <strong>USUARIO</strong>
            <p>
              Usuario se considera a toda aquella persona física, mayor de edad,
              que previamente se inscriba debidamente y acepte los presentes
              términos y condiciones. El Usuario podrá registrarse sin costo
              alguno, debiendo brindar determinados datos personales, los cuales
              redgaucho.com tratará de manera sensible. Para registrarse, el
              Usuario deberá ingresar en la plataforma redgaucho.com y brindar
              los siguientes datos personales: nombre, apellido, documento de
              identidad, dirección de correo electrónico y contraseña creada a
              estos efectos. De esta forma el Usuario creará su perfil, el cual
              luego podrá ampliar subiendo una foto y brindando información
              personal que le interese compartir, lo cual no será obligatorio
              para mantener la calidad de Usuario. Se tendrá la calidad de
              Usuario siempre que previamente lea y acepte los presentes
              términos y condiciones generales de uso. El Usuario podrá
              modificar su perfil cuando lo desee y podrá dar de baja su calidad
              de tal en cualquier momento.
            </p>

            <p>El Usuario deberá:</p>
            <ol>
              <li>
                Disponer de su propio acceso a INTERNET y abonar los gastos
                derivados de la conexión o servicio relacionados con el
                mencionado acceso. En tal sentido redgaucho.com no tendrá
                responsabilidad civil, penal o administrativa de ninguna especie
                por deficiencias en los equipos y/o el servicio de los que se
                sirva el Usuario para acceder a INTERNET, por los medios
                utilizados para acceder a INTERNET por parte del Usuario, así
                como los gastos que al Usuario o a terceros les supusiera el
                mentado acceso a INTERNET.
              </li>
              <li>
                No utilizar la plataforma con fines ilícitos, ni los prohibidos
                en este documento, leyes y reglamentos.
              </li>
              <li>
                Respetar los presentes términos y condiciones de uso, bajo pena
                de ser eliminada su calidad de Usuario en redgaucho.com.
              </li>
              <li>
                Aceptar los términos (EULA) tener claro que no hay tolerancia
                para contenido objetable o usuarios abusivos.
              </li>
            </ol>

            <strong>
              TRATAMIENTO INFORMATICO DE LOS DATOS DEL USUARIO. CUMPLIMIENTO DE
              LA LEY Nº 18.331.
            </strong>
            <p>
              El Usuario manifiesta que conoce y es consciente que los datos que
              suministre (en adelante Información de Registro) se incorporarán a
              la base de datos de la que es responsable redgaucho.com,
              actualmente en proceso de ser registrado en la Unidad Reguladora y
              de Control de Datos Personales.
            </p>

            <p>
              En este sentido, el Usuario conoce y es consciente de que la
              Información de Registro es recogida, gravada y conservada por
              redgaucho.com en sus servidores que permanecen a resguardo.
            </p>

            <p>Por lo tanto, el Usuario autoriza a redgaucho.com a:</p>
            <ul>
              <li>
                Conservar y utilizar los datos del Usuario para enviarle
                información que pueda considerar de su interés.
              </li>
              <li>
                Registrar y conservar la dirección IP desde la que el Usuario
                otorgó sus datos, el momento temporal en que lo hizo, así como
                otros datos relevantes a los efectos de prueba del
                consentimiento anteriormente referido, así como de verificación
                del comportamiento del Usuario y su uso del Servicio de
                redgaucho.com.
              </li>
            </ul>

            <strong>
              AJENIDAD DE CON redgaucho.com RESPECTO A LOS USUARIOS Y TERCERAS
              EMPRESAS QUE HAGAN PUBLICIDAD EN LA PLATAFORMA
            </strong>
            <p>
              redgaucho.com tiene absoluta independencia civil y comercial
              respecto de todos los Usuarios que utilicen el sitio web y la App,
              así como de la publicidad que se difunda en redgaucho.com. Por lo
              tanto, redgaucho.com:
            </p>
            <ul>
              <li>
                No tendrá responsabilidad civil, penal ni administrativa por el
                contenido que cada Usuario ingrese en la plataforma, así como
                tampoco de la veracidad del contenido del perfil de un Usuario.
              </li>
              <li>
                No garantiza que la publicidad que se encuentre en la plataforma
                y lleve a los Usuarios a adquirir productos y servicios
                promocionados dentro de las mismas cumplan con las expectativas
                que llevaron a los Usuarios a tal adquisición.
              </li>
              <li>
                No será responsable civil, penal ni administrativamente por los
                daños de cualquier naturaleza que pudiera recibir el Usuario
                como consecuencia, directa o indirecta, de cualquier tipo, por
                concepto del uso del sitio web, de la App, así como de la
                adquisición privada que realice a través de haber tomado
                contacto con la publicidad que en la plataforma se encuentre de
                empresas terceras.
              </li>
              <li>
                No será responsable ni civil, administrativa o penalmente de
                cualquier contenido compartido por los Usuarios o las empresas
                que publiciten en la plataforma en cualquier de sus formas por
                violación de Derechos de Propiedad Intelectual de cualquier
                naturaleza o Derecho de Imagen. En caso de recibir una denuncia
                por contenidos violatorios de estos Derechos u otros,
                redgaucho.com realizará el estudio pertinente pudiendo bloquear
                y dar de baja los contenidos que generen dudas o se acredite la
                existencia de violación de estos derechos.
              </li>
              <li>
                No garantiza que el sitio web y la plataforma funcione libre de
                errores o que los servidores estén libres de virus informáticos
                u otros mecanismos lesivos. Si por el uso de esta plataforma el
                Usuario tiene que acudir al servicio técnico o reponer el equipo
                o datos del mismo, redgaucho.com no se responsabiliza de estos
                gastos.
              </li>
            </ul>

            <strong>POTESTADES DE redgaucho.com:</strong>
            <p>redgaucho.com tendrá las siguientes facultades:</p>
            <ul>
              <li>
                Ceder el presente acuerdo con todos sus derechos y obligaciones
                ya sea por venta del servicio, cesión, disolución, escisión,
                fusión de empresas o de cualquier otra forma de transmisión, sin
                necesidad de efectuar un comunicado previo a los Usuarios del
                espacio.
              </li>
              <li>
                Dar de baja el sitio web y la App, sin exposición de motivos, en
                forma unilateral.
              </li>
              <li>
                Modificar la lista de temas, siempre vinculados al agro, para
                que los Usuarios interactúen comentando y realizando preguntas y
                respuestas sobre los mismos.
              </li>
              <li>
                Podrá bloquear el ingreso a la aplicación a Usuarios cuando
                éstos no cumplan con los términos y condiciones de uso del
                presente acuerdo.
              </li>
              <li>
                Podrá dar de baja contenidos que considere o que sus propios
                filtros de seguridad hayan constatado, siempre que los considere
                ilegales, contrarios a la moral y a las buenas costumbres, así
                como cuando sean violatorios de Derechos de terceros, incluyendo
                pero sin limitarse a Propiedad Intelectual.
              </li>
              <li>
                Modificar los presentes términos y condiciones de uso, sin
                comunicación o consulta previa a los Usuarios.
              </li>
              <li>
                Enviar notificaciones a los Usuarios vía email a los efectos de
                informarles que han recibido respuestas a preguntas que han
                realizado o comentarios a sus respuestas a preguntas de otros
                Usuarios.
              </li>
              <li>
                Contratar publicidad para la aplicación redgaucho.com sea cual
                fuere el contenido, forma, tipo y volumen de la misma, a la cual
                tendrán acceso todos los Usuarios registrados.
              </li>
            </ul>

            <strong>PRINCIPIO DE CONSERVACIÓN DE LA RELACIÓN:</strong>
            <p>
              Si un tribunal competente considerara que alguna disposición o
              disposiciones del presente acuerdo fuese contraria a la ley, tal o
              tales disposiciones serán redactadas nuevamente de forma tal que
              reflejen lo más fielmente posible las intenciones de las partes,
              mientras las otras disposiciones se mantendrán vigentes y
              aplicables.
            </p>

            <p>
              En el supuesto de que alguna de las cláusulas del presente Acuerdo
              resultara inválida o inaplicable, la parte válida o aplicable y
              las restantes disposiciones del Acuerdo se mantendrán vigentes y
              aplicables.
            </p>

            <strong>RENUNCIA DE DERECHOS Y OTROS:</strong>
            <p>
              Toda renuncia al derecho de reclamación (explícita o implícita) de
              cualquiera de las partes a cualquier infracción del presente
              Acuerdo no constituirá renuncia al derecho a reclamación por otra
              infracción o por infracciones subsiguientes.
            </p>

            <p>
              Este Acuerdo estará gobernado e interpretado de acuerdo con las
              leyes de la República Oriental del Uruguay, sin tener en cuenta
              los principios de conflictos de leyes. Las partes acuerdan que
              cualquier controversia o reclamación que surja de o esté
              relacionada con este Acuerdo será sometida a la jurisdicción
              exclusiva de los tribunales competentes de la República Oriental
              del Uruguay.
            </p>
          </CardBody>
        </Card>
      </Row>
    </React.Fragment>
  );
};

export default Terms;
