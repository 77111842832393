import React from "react";
import { useHistory } from "react-router-dom";

import { Button } from "reactstrap";

const Page404 = () => {
  const history = useHistory();
  return (
    <div className="text-center">
      <h1 className="display-1 font-weight-bold">404</h1>
      <p className="h1">Página no encontrada.</p>
      <p className="h2 font-weight-normal mt-3 mb-4">
        Es posible que se haya eliminado la página que estás buscando.
      </p>
      <Button onClick={() => history.push("/feed")} color="primary" size="lg">
        Volver al sitio web
      </Button>
    </div>
  );
};

export default Page404;
