import React, { useEffect, useState } from "react";

import Loader from "../Loader";

import InfiniteScroll from "react-infinite-scroll-component";
import { Card, CardBody } from "reactstrap";
import Question from "../../pages/feed/Question";
import { usePaginatedResource } from "../../utils/hooks";

const UserComments = ({ commentsData, userId }) => {
  const [isLoading, fetchMore] = usePaginatedResource({
    userId,
    type: "Comment",
    pageSize: 5,
  });
  const [comments, setComments] = useState([]);

  useEffect(() => {
    setComments(commentsData.data || []);
  }, [commentsData.data]);

  const answers = Object.values(
    comments
      .filter((a) => a.answer)
      .reduce((p, c) => {
        p[c.questionAnswerId] = c.answer;
        return p;
      }, {})
  );

  const questions = Object.values(
    answers
      .filter((a) => a.question)
      .reduce((p, c) => {
        p[c.questionId] = c.question;
        return p;
      }, {})
  );

  return (
    <React.Fragment>
      <InfiniteScroll
        className="results-list"
        dataLength={comments.length} //This is important field to render the next data
        next={fetchMore}
        hasMore={commentsData.page < commentsData.totalPages}
        loader={<h4 className="mb-3 text-center">Cargando..</h4>}
        endMessage={
          <Card className="mb-0 border">
            <CardBody className="text-center">
              <span>No hay más comentarios para mostrar</span>
            </CardBody>
          </Card>
        }
        scrollableTarget="wrapper"
      >
        {questions.map((question) => (
          <Question
            readOnly={true}
            defaultExpanded={true}
            question={question}
            key={question.id}
            commentUserId={parseInt(userId)}
          />
        ))}
      </InfiniteScroll>
      {isLoading ? (
        <div className="min-width-50 my-2">
          <Loader size="sm" />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default UserComments;
