import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  Alert,
} from "reactstrap";

import ReactQuill from "react-quill";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import RequiredSelect from "./RequiredSelect";

import Loader from "../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import InformationModal from "../InformationModal";
import { utils } from "../../utils/utils";
import { utils as awsUtils } from "../../utils/aws";
import { useCategories } from "../../providers";

const MAX_CHAR_QUESTION = 10000;
const IMAGE_TYPE_CONTENT = "IMAGE_TYPE_CONTENT";

const srcToFile = async (src, fileName, mimeType) =>
  fetch(src)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], fileName, { type: mimeType }));

const getImages = async (images) => {
  const files = await Promise.all(
    images.map((image, i) =>
      srcToFile(image, `questionImage-${i}`, "image/png")
    )
  );
  return files;
};

const modules = {
  toolbar: {
    container: [
      ["bold", "italic", "underline"], // toggled buttons

      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ align: [] }],

      ["clean"], // remove formatting button
      ["image"],
    ],
    handlers: {
      image: () => {
        document.getElementById("image-uploader")?.click();
      },
    },
  },
};

const QuestionModal = ({ onSubmit, onClose, question }) => {
  const { categoriesState } = useCategories();

  const [isLoading, setIsLoading] = useState(false);

  const [images, setImages] = useState(question?.images || []);
  const [title, setTitle] = useState(question?.title || "");
  const [content, setContent] = useState(question?.content || "");
  const [contentLength, setContentLength] = useState(
    question?.content.length || 0
  );
  const [selectedCategories, setSelectedCategories] = useState(
    (question?.categories || []).map((c) => {
      return { label: c.name, value: c.id };
    })
  );

  const animatedComponents = makeAnimated();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: () => {},
  });

  const [error, setError] = useState();

  const onQuestionSubmit = async (event) => {
    event.preventDefault();
    if (contentLength > MAX_CHAR_QUESTION) {
      setIsLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: `El contenido de la pregunta excede los ${MAX_CHAR_QUESTION} caracteres de largo máximo.`,
        onClose,
      });
    }
    setIsLoading(true);
    const files = await getImages(images.filter((i) => utils.isNewImage(i))); //only the new
    awsUtils.replaceUploadImagesWeb(files, IMAGE_TYPE_CONTENT).then(
      (imagesUrl) => {
        const questionImages = [
          ...imagesUrl.map((i) => i.url),
          ...images.filter((i) => !utils.isNewImage(i)),
        ];
        onSubmit({
          title,
          content,
          categoriesIds: selectedCategories.map((c) => c.value),
          images: questionImages,
        });
        setContent("");
        setImages([]);
      },
      (err) => {
        setIsLoading(false);
        setError(err.message);
      }
    );
  };

  const checkCharacterCount = (event) => {
    if (contentLength >= MAX_CHAR_QUESTION && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  const onChangeQuillEditor = (content, delta, source, editor) => {
    setContent(content.replace(/<p><br><\/p>/g, ""));
    setContentLength(editor.getText().length - 1);
  };

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
  };

  const categoriesOptions = categoriesState?.categories;

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              onClose: () => {},
            })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size={"lg"} toggle={onClose}>
      <ModalHeader>{question ? "Editar" : "Añadir"} Pregunta</ModalHeader>
      <Form onSubmit={onQuestionSubmit}>
        <ModalBody>
          <Input
            maxLength={255}
            required={true}
            onChange={(event) => setTitle(event.currentTarget.value)}
            type="text"
            name="title"
            id="title"
            value={title}
            placeholder={`Comienza tu pregunta con "Qué", "Cómo", "Por qué", etc`}
            style={{ height: "40px" }}
          />
          <RequiredSelect
            SelectComponent={Select}
            placeholder={
              <span className="text-muted">Selecciona las categorías..</span>
            }
            noOptionsMessage={() => "No hay categorías"}
            styles={style}
            className="categoriesSelect border rounded my-2"
            options={categoriesOptions.map((category) => ({
              label: category.name,
              value: category.id,
            }))}
            closeMenuOnSelect={true}
            components={animatedComponents}
            value={selectedCategories}
            isMulti
            onChange={(selected) => setSelectedCategories(selected)}
            required
          />
          <div className="border rounded flex-grow-1">
            <Input
              id="image-uploader"
              name="image-uploader"
              className="d-none"
              multiple={true}
              type="file"
              accept="image/*"
              onChange={(event) => {
                const imagesLength =
                  (images?.length || 0) + [...event.currentTarget.files].length;
                if (imagesLength > 4) {
                  return setInformationModal({
                    isOpen: true,
                    title: "Error",
                    body: `No puedes subir más de 4 fotos.`,
                    onClose: () =>
                      setInformationModal({
                        isOpen: false,
                        title: "",
                        body: "",
                        onClose: () => {},
                      }),
                  });
                }
                setImages([
                  ...images,
                  ...[...event.currentTarget.files].map((file) =>
                    URL.createObjectURL(file)
                  ),
                ]);
              }}
            />
            <ReactQuill
              className="min-height-250 react-quill"
              modules={modules}
              placeholder="Escribe el contenido de tu pregunta..."
              value={content}
              onChange={onChangeQuillEditor}
              onKeyDown={checkCharacterCount}
            />
            {images?.length ? (
              <div className="d-flex flex-wrap p-2">
                {images.map((image, i) => (
                  <div key={i} className="relative height-150 m-2">
                    <Button
                      className="close"
                      style={{
                        top: "-10px",
                        right: "-8px",
                        position: "absolute",
                      }}
                      color="none"
                      onClick={() => {
                        const newimages = [...images];
                        newimages.splice(i, 1);
                        setImages([...newimages]);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} size="xs" />
                    </Button>
                    <img
                      alt={`QuestionImage-${i}`}
                      src={image}
                      className="rounded border height-100 width-100"
                    />
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <small
            style={{ display: "flex" }}
            className="text-muted text-md-truncate justify-content-end pt-1"
          >
            {contentLength + "/" + MAX_CHAR_QUESTION}
          </small>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose} size="sm">
            Cancelar
          </Button>
          {!isLoading && !!error ? (
            <Alert className="alert-outline cursor-pointer" color="warning">
              <div className="alert-icon">
                <FontAwesomeIcon icon={faBell} fixedWidth />
              </div>
              <div className="alert-message text-left pr-5">
                <small>{error}</small>
              </div>
            </Alert>
          ) : null}
          <div className="min-width-50">
            {isLoading ? (
              <Loader size="sm" align="end" />
            ) : (
              <Button color="primary" type="submit" size="sm">
                {question ? "Guardar" : "Publicar"}
              </Button>
            )}
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default QuestionModal;
