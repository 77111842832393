import config from "../config/config";
import { utils } from "../utils/utils";
import APIKit from "./ApiKit";

export const awsApi = {
  signDocuments: (data) => {
    return APIKit.getInstance()(`/api/aws/sign`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => utils.handleError(err));
  },
  putDocumentsToS3: (data) => {
    return fetch(data.url, {
      method: "PUT",
      body: data.blob,
    })
      .then(() => {
        return { name: data.fileName, url: data.fileUrl };
      })
      .catch((err) => utils.handleError(err));
  },
};
