import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label,
} from "reactstrap";

import Loader from "../Loader";

const PasswordModal = ({ onSubmit, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [localData, setLocalData] = useState({});

  const doSubmit = (event) => {
    event.preventDefault();
    setIsLoading(false);
    onSubmit(localData);
  };

  return (
    <Modal isOpen={true} onClosed={onClose} toggle={onClose}>
      <ModalHeader>Editar contraseña</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          <FormGroup>
            <Label className="font-weight-500">Nueva contraseña</Label>
            <Input
              maxLength={50}
              type="password"
              name="password"
              className="mb-1 form-control"
              value={localData.newPassword || ""}
              placeholder="Ingresa tu nueva contraseña"
              onChange={(event) =>
                setLocalData({
                  ...localData,
                  newPassword: event.currentTarget.value,
                })
              }
            />
          </FormGroup>
          <FormGroup className="mb-0">
            <Label className="font-weight-500">Repite nueva contraseña</Label>
            <div className="d-flex flex-column">
              <Input
                maxLength={50}
                type="password"
                name="repassword"
                className={`mb-1 form-control ${
                  !localData.newPassword?.length ||
                  !localData.repassword?.length
                    ? ""
                    : localData.newPassword !== localData.repassword
                    ? "border-danger"
                    : "border-success"
                }`}
                value={localData.repassword || ""}
                placeholder="Reingresa tu nueva contraseña"
                onChange={(event) =>
                  setLocalData({
                    ...localData,
                    repassword: event.currentTarget.value,
                  })
                }
              />
              {localData.repassword?.length &&
              localData.newPassword !== localData.repassword ? (
                <span className="text-danger">Contraseñas no coinciden</span>
              ) : null}
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="d-flex flex-column">
          <div className="d-flex justify-content-between col-12 px-0 align-items-center">
            <Button color={"secondary"} onClick={onClose} size="sm">
              Cancelar
            </Button>
            {isLoading ? (
              <div className="min-width-50">
                <Loader size="sm" align="end" />
              </div>
            ) : (
              <Button
                disabled={
                  !localData.newPassword?.length ||
                  (localData.newPassword &&
                    localData.newPassword !== localData.repassword)
                }
                color="primary"
                type="submit"
                size="sm"
              >
                Guardar
              </Button>
            )}
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default PasswordModal;
