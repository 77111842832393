import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import { v4 as uuidv4 } from "uuid";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";

const uniqueIdentifier = uuidv4();

Sentry.init({
  dsn: "https://6fb07cdcac364d8082ce5f37f832307f@o912544.ingest.sentry.io/6573038",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [window.location.origin],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV || "development",
});

// Render app in `#root` element
ReactDOM.render(
  <App version={uniqueIdentifier} />,
  document.getElementById("root")
);
