import React, { useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Media,
} from "reactstrap";

import avatar from "../../assets/images/placeholder-3.png";

import moment from "moment";
import Loader from "../../components/Loader";
import ConfirmationModal from "../../components/ConfirmationModal";
import UserAnswer from "./UserAnswer";
import TimeAgo from "react-timeago";
import esStrings from "react-timeago/lib/language-strings/es";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import ReportModal from "../../components/customer/ReportModal";
import { useAuth } from "../../providers";
import { commonApi } from "../../services";
import { useHistory } from "react-router-dom";

const formatter = buildFormatter(esStrings);

moment.locale("es-es");

const MODE_ANSWER_COMMENT = 2;
const REPORT_TYPE_COMMENT = "REPORT_TYPE_COMMENT";

const createMarkup = (text) => {
  return { __html: text };
};

const Comment = ({ comment, onDeleted }) => {
  const [authContext] = useAuth();
  const history = useHistory();

  const [localComment, setLocalComment] = useState(comment);
  const [isLoading, setIsLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [reportModal, setReportModal] = useState(false);

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: () => {},
    onClose: () => {},
    title: "",
    body: "",
    confirmColor: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onDelete = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        setIsLoading(true);
        commonApi
          .deleteComment(
            localComment.questionId,
            localComment.questionAnswerId,
            localComment.id
          )
          .then(() => {
            setConfirmationModal(initConfirmationModal);
            onDeleted();
            setIsLoading(false);
          });
        return;
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Borrar Comentario",
      body: "¿Confirmas que deseas borrar el comentario?",
      confirmColor: "danger",
    });
  };

  const onEdit = () => setEditing(true);

  const onReport = () => setReportModal(true);

  return (
    <Media className="pt-3 px-3 not-first-border-top">
      <Media body className="col-12 px-0">
        {localComment.user?.id === authContext.currentUser?.id ? (
          isLoading ? (
            <div className="min-width-50 my-2">
              <Loader size="sm" />
            </div>
          ) : (
            <UncontrolledDropdown className="float-right">
              <DropdownToggle
                tag="button"
                className="btn btn-sm btn-link no-arrow card-drop p-0"
              >
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={onEdit}>Editar</DropdownItem>
                <DropdownItem onClick={onDelete}>Borrar</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        ) : isLoading ? (
          <div className="min-width-50 my-2">
            <Loader size="sm" />
          </div>
        ) : (
          <UncontrolledDropdown className="float-right">
            <DropdownToggle
              tag="button"
              className="btn btn-sm btn-link no-arrow card-drop p-0"
            >
              <i className="mdi mdi-dots-horizontal"></i>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={onReport}>Reportar</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
        <div className="d-flex">
          <div
            className="mr-2 rounded-circle"
            style={{
              backgroundPosition: "center",
              backgroundImage: `url(${localComment.user.image || avatar})`,
              width: "32px",
              height: "32px",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              cursor: "pointer",
              alignSelf: "center",
            }}
          ></div>
          <div className="d-flex flex-column">
            <h5 className="m-0">{`${localComment.user.firstName} ${localComment.user.lastName}`}</h5>
            <p className="text-muted mb-0 small">
              <TimeAgo date={localComment.updatedAt} formatter={formatter} />
            </p>
          </div>
        </div>
        {editing ? (
          <div className="my-2">
            <UserAnswer
              editing={true}
              parent={localComment.answerId}
              value={localComment.content}
              id={localComment.id}
              images={[]}
              mode={MODE_ANSWER_COMMENT}
              onSubmit={(event, content) => {
                event.preventDefault();
                setEditing(false);
                commonApi
                  .updateComment(
                    localComment.questionId,
                    localComment.answerId,
                    localComment.id,
                    { content }
                  )
                  .then((result) => setLocalComment(result));
              }}
            />
          </div>
        ) : (
          <p
            className="my-2 answer-content"
            dangerouslySetInnerHTML={createMarkup(localComment.content)}
          ></p>
        )}
      </Media>
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
      {reportModal ? (
        <ReportModal
          onClose={() => setReportModal(false)}
          onSubmit={() => {
            setReportModal(false);
            history.go(0);
          }}
          type={REPORT_TYPE_COMMENT}
          contentId={localComment.id}
        />
      ) : null}
    </Media>
  );
};

export default Comment;
