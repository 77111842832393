import React, { useState } from "react";

import {
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";

import classnames from "classnames";

import avatar from "../../assets/images/placeholder-3.png";

import moment from "moment";
import UserAnswer from "./UserAnswer";
import Comment from "./Comment";
import Loader from "../../components/Loader";
import ConfirmationModal from "../../components/ConfirmationModal";

import TimeAgo from "react-timeago";
import esStrings from "react-timeago/lib/language-strings/es";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

import ReportModal from "../../components/customer/ReportModal";
import InformationModal from "../../components/InformationModal";
import { useAuth } from "../../providers";
import { commonApi } from "../../services";
import { useHistory } from "react-router-dom";

const formatter = buildFormatter(esStrings);

const MODE_QUESTION_ANSWER = 1;
const REPORT_TYPE_QUESTION_ANSWER = "REPORT_TYPE_QUESTION_ANSWER";

moment.locale("es-es");

const VOTE_POSITIVE = 1;
const VOTE_NEGATIVE = 2;

const MODE_ANSWER_COMMENT = 2;

const createMarkup = (text) => {
  return { __html: text };
};

const Answer = ({ answer, onDeleted, readOnly, onRefresh }) => {
  const [authContext] = useAuth();
  const history = useHistory();

  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: null,
  });

  const [localAnswer, setLocalAnswer] = useState(answer);
  const [isCommenting, setIsCommenting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [voting, setVoting] = useState(false);
  const [editing, setEditing] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [expanded, setExpanded] = useState();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: () => {},
    onClose: () => {},
    title: "",
    body: "",
    confirmColor: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onUserVote = (vote) => {
    if (voting) {
      return;
    }
    setVoting(true);
    let unVote = false;
    const currentUserVoteId = localAnswer.currentUserVote?.id;
    if (vote === VOTE_POSITIVE) {
      if (localAnswer.currentUserVote?.vote === VOTE_NEGATIVE) {
        localAnswer.dislikes = localAnswer.dislikes - 1;
        localAnswer.likes = localAnswer.likes + 1;
        localAnswer.currentUserVote.vote = VOTE_POSITIVE;
      } else if (localAnswer.currentUserVote?.vote === VOTE_POSITIVE) {
        unVote = true;
        localAnswer.likes = localAnswer.likes - 1;
        delete localAnswer.currentUserVote;
      } else {
        localAnswer.likes = localAnswer.likes + 1;
        localAnswer.currentUserVote = {
          vote: VOTE_POSITIVE,
        };
      }
    } else {
      if (localAnswer.currentUserVote?.vote === VOTE_POSITIVE) {
        localAnswer.likes = localAnswer.likes - 1;
        localAnswer.dislikes = localAnswer.dislikes + 1;
        localAnswer.currentUserVote.vote = VOTE_NEGATIVE;
      } else if (localAnswer.currentUserVote?.vote === VOTE_NEGATIVE) {
        unVote = true;
        localAnswer.dislikes = localAnswer.dislikes - 1;
        delete localAnswer.currentUserVote;
      } else {
        localAnswer.dislikes = localAnswer.dislikes + 1;
        localAnswer.currentUserVote = {
          vote: VOTE_NEGATIVE,
        };
      }
    }
    setLocalAnswer({
      ...localAnswer,
      currentUserVote: { ...localAnswer.currentUserVote },
    });
    if (unVote) {
      commonApi
        .unVoteAnswer(answer.questionId, answer.id, currentUserVoteId)
        .then(() =>
          commonApi.getAnswer(answer.questionId, answer.id).then((answer) => {
            setVoting(false);
            setLocalAnswer(answer);
          })
        );
    } else {
      commonApi.voteAnswer(answer.questionId, answer.id, { vote }).then(() =>
        commonApi.getAnswer(answer.questionId, answer.id).then((answer) => {
          setVoting(false);
          setLocalAnswer(answer);
        })
      );
    }
  };

  const onComment = (event, content) => {
    event.preventDefault();
    setIsLoading(true);
    setIsCommenting(false);
    commonApi
      .commentAnswer(answer.questionId, answer.id, {
        content,
      })
      .then(() => {
        setIsLoading(false);
        onRefresh();
        commonApi.getAnswer(answer.questionId, answer.id).then((answer) => {
          setLocalAnswer(answer);
        });
      });
  };

  const onDelete = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        setIsLoading(true);
        commonApi.deleteAnswer(answer.questionId, answer.id).then(() => {
          setIsLoading(false);
          setConfirmationModal(initConfirmationModal);
          onDeleted();
        });
        return;
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Borrar Respuesta",
      body: "¿Confirmas que deseas borrar la respuesta?",
      confirmColor: "danger",
    });
  };

  const onDeleteComment = (index) => {
    let comments = [...answer.comments];
    comments.splice(index, 1);
    setLocalAnswer({
      ...localAnswer,
      comments,
    });
  };

  const onEdit = () => setEditing(true);

  const onReport = () => setReportModal(true);

  const onImagePreview = (image) => {
    setInformationModal({
      isOpen: true,
      title: "Imagen",
      rawBody: true,
      body: (
        <img
          alt={"Imagen de Respuesta"}
          src={image}
          style={{ width: "100%", height: "auto" }}
        />
      ),
    });
  };

  const date =
    localAnswer.updatedAt !== localAnswer.createdAt
      ? localAnswer.updatedAt
      : localAnswer.createdAt;

  return (
    <Media className="pt-3 px-1 not-first-border-top">
      <Media body className="max-width-100">
        {localAnswer.user?.id === authContext.currentUser?.id ? (
          isLoading ? (
            <div className="min-width-50 my-2">
              <Loader size="sm" />
            </div>
          ) : (
            <UncontrolledDropdown className="float-right">
              <DropdownToggle
                tag="button"
                className="btn btn-sm btn-link no-arrow card-drop p-0"
              >
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={onEdit}>Editar</DropdownItem>
                <DropdownItem onClick={onDelete}>Borrar</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        ) : isLoading ? (
          <div className="min-width-50 my-2">
            <Loader size="sm" />
          </div>
        ) : (
          <UncontrolledDropdown className="float-right">
            <DropdownToggle
              tag="button"
              className="btn btn-sm btn-link no-arrow card-drop p-0"
            >
              <i className="mdi mdi-dots-horizontal"></i>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={onReport}>Reportar</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
        <div className="d-flex">
          <div
            className="mr-2 rounded-circle"
            style={{
              backgroundPosition: "center",
              backgroundImage: `url(${localAnswer.user.image || avatar})`,
              width: "32px",
              height: "32px",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              cursor: "pointer",
            }}
          ></div>
          <div className="d-flex flex-column">
            <h5 className="m-0">{`${localAnswer.user.firstName} ${localAnswer.user.lastName}`}</h5>
            <p className="text-muted mb-0 small">
              <TimeAgo date={date} formatter={formatter} />
            </p>
          </div>
        </div>
        {editing ? (
          <div className="my-2">
            <UserAnswer
              editing={true}
              parent={localAnswer.questionId}
              id={localAnswer.id}
              value={localAnswer.content}
              images={localAnswer.images}
              mode={MODE_QUESTION_ANSWER}
              onSubmit={(event, content, images) => {
                event.preventDefault();
                setEditing(false);
                commonApi
                  .updateAnswer(localAnswer.questionId, localAnswer.id, {
                    content,
                    images,
                  })
                  .then((result) => setLocalAnswer(result));
              }}
            />
          </div>
        ) : (
          <div>
            <p
              className="my-2 answer-content"
              dangerouslySetInnerHTML={createMarkup(localAnswer.content)}
            ></p>
            {localAnswer.images?.length ? (
              <div className="d-flex height-64 row mb-1">
                {localAnswer.images.map((image, i) => (
                  <div
                    key={i}
                    className="relative height-64 mx-2 cursor-pointer"
                    onClick={() => onImagePreview(image)}
                  >
                    <img
                      alt={`AnswerImage-${i}`}
                      src={image}
                      className="rounded border height-100"
                    />
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        )}
        {!readOnly ? (
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <span
                className="btn btn-sm btn-link text-muted p-0"
                onClick={() => onUserVote(VOTE_POSITIVE)}
              >
                <i
                  className={classnames("uil", "uil-thumbs-up", "mr-05", {
                    "text-success":
                      localAnswer.currentUserVote?.vote === VOTE_POSITIVE,
                  })}
                ></i>{" "}
                {localAnswer.likes}
              </span>
              <span
                className="btn btn-sm btn-link text-muted p-0 mx-2"
                onClick={() => onUserVote(VOTE_NEGATIVE)}
              >
                <i
                  className={classnames("uil", "uil-thumbs-down", "mr-05", {
                    "text-danger":
                      localAnswer.currentUserVote?.vote === VOTE_NEGATIVE,
                  })}
                ></i>{" "}
                {localAnswer.dislikes}
              </span>
              <span
                onClick={() => setIsCommenting(!isCommenting)}
                className="btn btn-sm btn-link text-muted p-0"
              >
                <i className="uil uil-comments-alt mr-05"></i> Comentar
              </span>
            </div>
            <div
              className="d-flex justify-content-end flex-shrink-0"
              onClick={() =>
                localAnswer.comments.length ? setExpanded(!expanded) : null
              }
            >
              <span className="btn btn-sm btn-link text-muted pl-0 flex-shrink-0">
                <i className="uil uil-comments-alt mr-1"></i>
                {localAnswer.comments.length}{" "}
                {localAnswer.comments.length === 1
                  ? "Comentario"
                  : "Comentarios"}
              </span>
            </div>
          </div>
        ) : null}
        {isCommenting ? (
          !readOnly ? (
            <div className="mt-0 p-2 bg-light rounded mb-2 border">
              <UserAnswer
                parent={localAnswer.id}
                images={[]}
                value={null}
                onSubmit={onComment}
                mode={MODE_ANSWER_COMMENT}
              />
            </div>
          ) : null
        ) : null}
        {expanded
          ? localAnswer.comments && (
              <React.Fragment>
                {localAnswer.comments.map((comment, index) => {
                  return (
                    <Comment
                      key={comment.id}
                      comment={{
                        ...comment,
                        questionId: localAnswer.questionId,
                      }}
                      onDeleted={() => onDeleteComment(index)}
                    />
                  );
                })}
              </React.Fragment>
            )
          : null}
      </Media>
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          rawBody={informationModal.rawBody}
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  rawBody: false,
                  isOpen: false,
                  title: "",
                  body: null,
                })
          }
        />
      ) : reportModal ? (
        <ReportModal
          onClose={() => setReportModal(false)}
          onSubmit={() => {
            setReportModal(false);
            history.go(0);
          }}
          type={REPORT_TYPE_QUESTION_ANSWER}
          contentId={localAnswer.id}
        />
      ) : null}
    </Media>
  );
};

export default Answer;
