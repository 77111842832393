import React, { useEffect, useRef, useState } from "react";
import {
  InputGroup,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
  Alert,
} from "reactstrap";

import Wrapper from "../components/Wrapper";
import Main from "../components/Main";

import Loader from "../components/Loader";
import { useHistory } from "react-router-dom";
import InformationModal from "../components/InformationModal";
import logo from "../assets/images/logo_horiz.png";
import logoSm from "../assets/images/logo-gaucho-sm.png";
import Content from "../components/Content";
import useElementSize from "../hooks/useElementSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faHome,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import config from "../config/config";
import {
  useAuth,
  useCategories,
  useNotifications,
  useProfile,
} from "../providers";
import { authApi, commonApi } from "../services";

const UNFOLLOW = 1;
const FOLLOW = 2;

const getImage = (category) =>
  category.image
    ? `${config.apiURL}/images/categories/${category.image}`
    : `${config.apiURL}/images/categories/category-placeholder-4.png`;

const CategoriesInital = () => {
  const [, setAuthContext] = useAuth();
  const history = useHistory();

  const cardRef = useRef(null);

  const { width } = useElementSize(cardRef);

  const { categoriesState, dispatch } = useCategories();
  const { dispatchNotificationsChange } = useNotifications();
  const { dispatch: dispatchProfileChange } = useProfile();

  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [followLoading, setFollowLoading] = useState({});

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const categories = categoriesState?.categories;

  const isFollowing = (id) =>
    categoriesState?.followed.indexOf(parseInt(id)) > -1;

  const logout = () => {
    authApi.logout();
    setAuthContext({ currentUser: null });
    history.push("/auth/sign-in");
    dispatch({
      type: "resetStatus",
    });
    dispatchNotificationsChange({
      type: "resetStatus",
    });
    dispatchProfileChange({
      type: "resetStatus",
    });
  };

  useEffect(() => {
    if (!categories?.length) {
      setIsLoading(true);
      commonApi.getCategories().then((categories) => {
        setIsLoading(false);
        dispatch({
          type: "getCategoriesSuccess",
          payload: categories,
        });
      });
    }
  }, [dispatch, categories]);

  const onFollow = (id, action) => {
    const oldFollowed = categoriesState?.followed.slice();
    const followed = categoriesState?.followed;
    if (action === UNFOLLOW) {
      followed.splice(followed.indexOf(id), 1);
    } else {
      followed.push(id);
    }
    dispatch({
      type: "followCategoriesSuccess",
      payload: { followed: followed, oldFollowed: oldFollowed },
    });
  };

  const toggleFollow = (id) => {
    setFollowLoading({ [id]: true });
    if (isFollowing(id)) {
      if (categoriesState?.followed.length === 1) {
        setFollowLoading({ [id]: false });
        return setInformationModal({
          isOpen: true,
          title: "Dejar de seguir",
          body: "Debes seguir al menos un tema de interés.",
        });
      }
      commonApi
        .unfollowCategory(id)
        .then(() => {
          setFollowLoading({ [id]: false });
          onFollow(parseInt(id), UNFOLLOW);
        })
        .catch(() => {
          setFollowLoading({ [id]: false });
          onFollow(parseInt(id), FOLLOW);
        });
    } else {
      commonApi
        .followCategories([id])
        .then(() => {
          setFollowLoading({ [id]: false });
          onFollow(parseInt(id), FOLLOW);
        })
        .catch(() => {
          setFollowLoading({ [id]: false });
          onFollow(parseInt(id), UNFOLLOW);
        });
    }
  };

  const onContinue = () => {
    if (!categoriesState?.followed.length) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "Debes seguir al menos un tema de interés para continuar.",
      });
    }
    history.push("/feed");
  };

  const followedCategories = categories.filter(
    (c) =>
      isFollowing(c.id) &&
      (search?.length
        ? c.name.toLowerCase().indexOf(search.toLowerCase()) > -1
        : true)
  );
  const otherCategories = categories.filter(
    (c) =>
      !isFollowing(c.id) &&
      (search?.length
        ? c.name.toLowerCase().indexOf(search.toLowerCase()) > -1
        : true)
  );

  return (
    <React.Fragment>
      <Wrapper className="overflow-x-hidden">
        <Main className="d-flex flex-column justify-content-between">
          {isLoading ? (
            <Loader />
          ) : (
            <div>
              <div className="navbar-custom topnav-navbar">
                <div className="navbar-container d-flex align-items-center justify-content-between">
                  <a href="/">
                    <span className="cursor-pointer d-none d-md-none d-lg-block topnav-logo text-center font-weight-bold px-1 px-lg-3 flex-shrink-0 px-0">
                      <img
                        className="max-height-50 px-0"
                        src={logo}
                        alt={"Red Gaucho"}
                      ></img>
                    </span>
                    <span className="cursor-pointer d-lg-none topnav-logo text-center font-weight-bold px-1 px-lg-3 flex-shrink-0 px-0">
                      <img
                        className="max-width-50 px-0"
                        src={logoSm}
                        alt={"Red Gaucho"}
                      ></img>
                    </span>
                  </a>
                  <div className="d-none d-md-block app-search ml-4 flex-grow-1 px-2">
                    <InputGroup>
                      <Input
                        className="form-control dropdown-toggle"
                        placeholder={"Buscar..."}
                        required={true}
                        maxLength={50}
                        value={search}
                        onChange={(evt) => setSearch(evt.target.value)}
                      />
                      <span className="mdi mdi-magnify search-icon mr-2"></span>
                    </InputGroup>
                  </div>
                  <div className="flex-grow-1 d-flex align-items-center justify-content-end pl-0 pr-1 px-lg-3">
                    <ul className="ml-1 flex-shrink-0 justify-content-end list-unstyled topbar-right-menu float-right mb-0 d-flex align-items-center">
                      <li
                        onClick={onContinue}
                        className="border-left notification-list px-2 py-3 d-flex align-items-center cursor-pointer"
                      >
                        <FontAwesomeIcon
                          className="text-primary"
                          icon={faHome}
                          size="lg"
                        />
                        <span className="ml-1 font-weight-500">Continuar</span>
                      </li>
                      <li
                        onClick={logout}
                        className="border-left notification-list px-2 py-3 d-flex align-items-center cursor-pointer"
                      >
                        <FontAwesomeIcon
                          className="text-primary"
                          icon={faSignOutAlt}
                          size="lg"
                        />
                        <span className="ml-1 font-weight-500">Salir</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  style={{ boxShadow: "0 3px 6px rgb(0 0 0 / 4%)" }}
                  className="d-md-none d-block app-search bg-white border-top border-bottom col-12 mb-3 p-2 bg-white"
                >
                  <InputGroup>
                    <Input
                      className="form-control dropdown-toggle"
                      placeholder={"Buscar..."}
                      required={true}
                      maxLength={50}
                      value={search}
                      onChange={(evt) => setSearch(evt.target.value)}
                    />
                    <span className="mdi mdi-magnify search-icon mr-2"></span>
                  </InputGroup>
                </div>
              </div>
              <Content>
                <Row className="mt-3">
                  <Col className="order-md-1 order-lg-1 col-12">
                    <h4 className="pl-2">Temas de interés seguidos</h4>
                    <div
                      className={`d-flex flex-wrap mt-2 col-12 ${
                        followedCategories.length ? "row" : ""
                      }`}
                    >
                      {followedCategories.length ? (
                        followedCategories.map((category, i) => (
                          <div
                            key={category.id}
                            className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex"
                          >
                            <Card className="col-12 border px-0">
                              <CardHeader className="p-0">
                                <div
                                  ref={i === 0 ? cardRef : null}
                                  style={{
                                    backgroundPosition: "center",
                                    backgroundImage: `url(${getImage(
                                      category
                                    )})`,
                                    width: "100%",
                                    height: width / 2,
                                    backgroundSize: "cover",
                                  }}
                                ></div>
                              </CardHeader>
                              <CardBody className="py-3 d-flex flex-column justify-content-between">
                                <div className="text-center">
                                  <CardTitle tag="h5">
                                    {category?.name || ""}
                                  </CardTitle>
                                  <CardSubtitle
                                    tag="h6"
                                    className="mb-2 text-muted"
                                  >
                                    <small>
                                      {category?.totalQuestions} preguntas
                                    </small>
                                    <small>
                                      , {category?.totalFollowers}{" "}
                                      {category?.totalFollowers === 1
                                        ? "seguidor"
                                        : "seguidores"}
                                    </small>
                                  </CardSubtitle>
                                  <CardText>
                                    <small>{category?.description}</small>
                                  </CardText>
                                </div>
                                <div className="d-flex align-items-center justify-content-center mt-2">
                                  {followLoading[category.id] ? (
                                    <Loader size="sm" align="center" />
                                  ) : isFollowing(category.id) ? (
                                    <Button
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        toggleFollow(category.id);
                                      }}
                                      size="sm"
                                      className="btn btn-danger cursor-pointer"
                                    >
                                      Dejar de Seguir
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        toggleFollow(category.id);
                                      }}
                                      size="sm"
                                      className="btn btn-success cursor-pointer"
                                    >
                                      Seguir
                                    </Button>
                                  )}
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        ))
                      ) : (
                        <div className="col-12 bg-white p-2 text-center rounded border d-flex align-items-center justify-content-center">
                          {search?.length ? (
                            "No se encontraron temas"
                          ) : (
                            <Alert
                              className="alert-outline cursor-pointer m-0 d-flex align-items-center"
                              color="warning"
                            >
                              <div className="alert-icon">
                                <FontAwesomeIcon icon={faBell} fixedWidth />
                              </div>
                              <div className="alert-message text-center py-0 px-1 px-lg-3 border-0">
                                <small className="text-warning font-weight-500">
                                  Sigue al menos un tema de interés para
                                  continuar
                                </small>
                              </div>
                            </Alert>
                          )}
                        </div>
                      )}
                    </div>
                    <h4 className="pl-2 mt-3">Temas de interés para seguir</h4>
                    <div
                      className={`d-flex flex-wrap my-2 col-12 ${
                        otherCategories.length ? "row" : ""
                      }`}
                    >
                      {otherCategories.length ? (
                        otherCategories.map((category, i) => (
                          <div
                            key={category.id}
                            className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex"
                          >
                            <Card className="col-12 border px-0">
                              <CardHeader className="p-0">
                                <div
                                  ref={i === 0 ? cardRef : null}
                                  style={{
                                    backgroundPosition: "center",
                                    backgroundImage: `url(${getImage(
                                      category
                                    )})`,
                                    width: "100%",
                                    height: width / 2,
                                    backgroundSize: "cover",
                                  }}
                                ></div>
                              </CardHeader>
                              <CardBody className="py-3 d-flex flex-column justify-content-between">
                                <div className="text-center">
                                  <CardTitle tag="h5">
                                    {category?.name || ""}
                                  </CardTitle>
                                  <CardSubtitle
                                    tag="h6"
                                    className="mb-2 text-muted"
                                  >
                                    <small>
                                      {category?.totalQuestions} preguntas
                                    </small>
                                    <small>
                                      , {category?.totalFollowers}{" "}
                                      {category?.totalFollowers === 1
                                        ? "seguidor"
                                        : "seguidores"}
                                    </small>
                                  </CardSubtitle>
                                  <CardText>
                                    <small>{category?.description}</small>
                                  </CardText>
                                </div>
                                <div className="d-flex align-items-center justify-content-center mt-2">
                                  {followLoading[category.id] ? (
                                    <Loader size="sm" align="center" />
                                  ) : isFollowing(category.id) ? (
                                    <Button
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        toggleFollow(category.id);
                                      }}
                                      size="sm"
                                      className="btn btn-danger cursor-pointer"
                                    >
                                      Dejar de Seguir
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        toggleFollow(category.id);
                                      }}
                                      size="sm"
                                      className="btn btn-success cursor-pointer"
                                    >
                                      Seguir
                                    </Button>
                                  )}
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        ))
                      ) : (
                        <div className="col-12 bg-white p-2 text-center rounded border">
                          {search?.length
                            ? "No se encontraron temas"
                            : "Ya sigues todos los temas"}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Content>
            </div>
          )}
        </Main>
        {informationModal.isOpen ? (
          <InformationModal
            title={informationModal.title}
            body={informationModal.body}
            onClose={() =>
              setInformationModal({ isOpen: false, title: "", body: "" })
            }
          />
        ) : null}
      </Wrapper>
    </React.Fragment>
  );
};

export default CategoriesInital;
