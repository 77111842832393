import React from "react";

import { useHistory } from "react-router-dom";
import config from "../config/config";

const getImage = (category) =>
  category.image
    ? `${config.apiURL}/images/categories/${category.image}`
    : `${config.apiURL}/images/categories/category-placeholder-4.png`;

const CategoryItem = ({ index, category }) => {
  const history = useHistory();

  return (
    <div
      onClick={() => history.push(`/categories/${category.id}`)}
      key={index}
      className="inbox-item d-flex align-items-center justify-content-start col-12 px-0 cursor-pointer"
    >
      <div
        className="rounded border flex-shrink-0"
        style={{
          backgroundPosition: "center",
          backgroundImage: `url(${getImage(category)})`,
          width: "96px",
          height: "48px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          cursor: "pointer",
          alignSelf: "center",
        }}
      ></div>
      <div className="mb-0 inbox-item-author d-flex flex-column pl-2">
        <span className="">{category.name}</span>
        <small className="text-muted text-md-truncate">
          {category.description || ""}
        </small>
      </div>
    </div>
  );
};

export default CategoryItem;
