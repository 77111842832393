import React, { useState } from "react";

import {
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  CustomInput,
  FormGroup,
  Form,
} from "reactstrap";
import InformationModal from "../InformationModal";
import {
  CONTENT_REPORT_REASONS,
  USER_REPORT_REASONS,
  REPORT_TYPE_COMMENT,
  REPORT_TYPE_QUESTION,
  REPORT_TYPE_QUESTION_ANSWER,
  REPORT_TYPE_USER,
} from "../../constants";
import { commonApi } from "../../services";

const ReportModal = ({ onSubmit, onClose, type, contentId }) => {
  const [selected, setSelected] = useState();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: () => {},
  });

  const doSubmit = (event) => {
    event.preventDefault();
    let userId, questionId, questionAnswerId, commentId;
    switch (type) {
      case REPORT_TYPE_QUESTION:
        questionId = contentId;
        break;
      case REPORT_TYPE_QUESTION_ANSWER:
        questionAnswerId = contentId;
        break;
      case REPORT_TYPE_COMMENT:
        commentId = contentId;
        break;
      case REPORT_TYPE_USER:
        userId = contentId;
        break;
      default:
        break;
    }
    commonApi
      .reportContent({
        type,
        reason: selected,
        userId,
        questionId,
        questionAnswerId,
        commentId,
      })
      .then(() => {
        setInformationModal({
          isOpen: true,
          title: `Reportar ${
            type === REPORT_TYPE_USER ? "Usuario" : "Contenido"
          }`,
          body: (
            <>
              <p>
                {type === REPORT_TYPE_USER ? "Usuario" : "Contenido"} reportado
                con éxito.
              </p>
              <p>
                Nuestro equipo evaluará el reporte y será eliminado en caso de
                corresponder.
              </p>
              <span>Muchas Gracias.</span>
            </>
          ),
          onClose: () => onSubmit(),
        });
      });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      rawBody={true}
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              onClose: () => {},
            })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>
        Reportar {type === REPORT_TYPE_USER ? "Usuario" : "Contenido"}
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="d-flex flex-column">
          {(type === REPORT_TYPE_USER
            ? USER_REPORT_REASONS
            : CONTENT_REPORT_REASONS
          ).map((reason) => (
            <FormGroup key={reason.id}>
              <Col className="col-12 d-flex flex-column my-1">
                <CustomInput
                  id={reason.id}
                  type="checkbox"
                  label={reason.title}
                  checked={selected === reason.id}
                  onChange={(event) =>
                    setSelected(event.currentTarget.checked ? reason.id : null)
                  }
                  className="cursor-pointer text-left"
                />
                <small className="ml-3">{reason.description}</small>
              </Col>
            </FormGroup>
          ))}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancelar
          </Button>{" "}
          <Button disabled={!selected} color={"primary"} type={"submit"}>
            Reportar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ReportModal;
