import React, { useCallback, useEffect, useState } from "react";

import { Card, CardBody } from "reactstrap";

import Loader from "../../components/Loader";

import avatar from "../../assets/images/placeholder-3.png";

import moment from "moment";

import QuestionModal from "../../components/customer/QuestionModal";
import Question from "./Question";

import InfiniteScroll from "react-infinite-scroll-component";
import { useAuth, useQuestions } from "../../providers";
import { commonApi } from "../../services";

moment.locale("es-es");

const FormPost = () => {
  const [authContext] = useAuth();
  const [newQuestionModal, setNewQuestionModal] = useState(false);
  const { dispatchQuestionsChange } = useQuestions();

  return (
    <Card className="border">
      <CardBody className="p-0">
        <div
          className="p-3 d-flex flex-column cursor-pointer"
          onClick={() => setNewQuestionModal(true)}
        >
          <div className="d-flex align-items-center">
            <div
              className="mr-2 rounded-circle"
              style={{
                backgroundPosition: "center",
                backgroundImage: `url(${
                  authContext.currentUser.image || avatar
                })`,
                width: "24px",
                height: "24px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                cursor: "pointer",
                alignSelf: "center",
              }}
            ></div>
            <small className="text-muted">{`${authContext.currentUser.firstName} ${authContext.currentUser.lastName}`}</small>
          </div>
          <div className="mt-2">
            <h4 className="text-muted m-0">¿Cuál es tu pregunta?</h4>
          </div>
        </div>
      </CardBody>
      {newQuestionModal ? (
        <QuestionModal
          onSubmit={({ title, content, categoriesIds, images }) => {
            setNewQuestionModal(false);
            commonApi
              .saveQuestion({
                title,
                content,
                categoriesIds,
                images,
              })
              .then(() =>
                dispatchQuestionsChange({
                  type: "refreshQuestions",
                })
              );
          }}
          onClose={() => setNewQuestionModal(false)}
          question={null}
        />
      ) : null}
    </Card>
  );
};

// feed
const Feed = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);
  const { questionsState, dispatchQuestionsChange } = useQuestions();

  const dispatchQuestionsChangeCb = useCallback(
    (data) => dispatchQuestionsChange(data),
    [dispatchQuestionsChange]
  );

  useEffect(() => {
    if (questionsState?.page === 0) {
      setGeneralLoading(true);
    } else {
      setPageLoading(true);
    }
    commonApi
      .getFeed({
        page: questionsState?.page,
        pageSize: questionsState?.pageSize,
      })
      .then((questionState) => {
        dispatchQuestionsChangeCb({
          type: "getQuestions",
          payload: questionState,
        });
        setPageLoading(false);
        setGeneralLoading(false);
      });
  }, [
    dispatchQuestionsChangeCb,
    questionsState?.refresh,
    questionsState?.page,
    questionsState?.pageSize,
  ]);

  const questions = questionsState?.questions;

  return (
    <React.Fragment>
      <FormPost />
      {generalLoading ? (
        <div className="min-width-50 my-2">
          <Loader size="sm" />
        </div>
      ) : (
        <InfiniteScroll
          dataLength={questions.length} //This is important field to render the next data
          next={() => dispatchQuestionsChange({ type: "onNewPage" })}
          hasMore={questionsState?.page < questionsState?.totalPages}
          loader={<h4 className="mb-3 text-center">Cargando..</h4>}
          endMessage={
            <Card className="mb-0 border">
              <CardBody className="text-center">
                <span>No hay más preguntas para mostrar</span>
              </CardBody>
            </Card>
          }
          scrollableTarget="wrapper"
        >
          {questions.length
            ? questions.map((question) => (
                <Question
                  readOnly={false}
                  defaultExpanded={false}
                  question={question}
                  key={`${question.id}-${question.updatedAt}`}
                />
              ))
            : null}
        </InfiniteScroll>
      )}
      {pageLoading ? (
        <div className="min-width-50 my-2">
          <Loader size="sm" />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default Feed;
