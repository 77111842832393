import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import avatar from "../assets/images/placeholder-3.png";
import UserQuestions from "../components/profile/UserQuestions";
import UserAnswers from "../components/profile/UserAnswers";
import UserComments from "../components/profile/UserComments";
import MyCategories from "./feed/MyCategories";
import Loader from "../components/Loader";
import UserModal from "../components/profile/UserModal";
import ReportModal from "../components/customer/ReportModal";
import { useHistory } from "react-router";
import InformationModal from "../components/InformationModal";
import PasswordModal from "../components/profile/PasswordModal";
import { useAuth, useProfile } from "../providers";
import { commonApi } from "../services";

const QUESTIONS_TAB = 1;
const ANSWERS_TAB = 2;
const COMMENTS_TAB = 3;

const REPORT_TYPE_USER = "REPORT_TYPE_USER";

const Profile = ({ match }) => {
  const { id } = match.params;
  const [authContext, setAuthContext] = useAuth();
  const { state } = useProfile();
  const history = useHistory();

  const [user, setUser] = useState();
  const [reportModal, setReportModal] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);

  const [profileLoading, setProfileLoading] = useState(false);
  const [followLoading, setFollowLoading] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    rawBody: false,
  });

  const [activeTab, setActiveTab] = useState(QUESTIONS_TAB);
  const handleTab = (tab) => tab !== activeTab && setActiveTab(tab);

  const isOwnProfile = () => authContext.currentUser.id === parseInt(id);

  const onReport = () => setReportModal(true);

  const onEdit = () => setUserModal(true);

  const onEditPassword = () => setPasswordModal(true);

  useEffect(() => {
    if (authContext.currentUser.id !== parseInt(id)) {
      setProfileLoading(true);
      commonApi.getUserInfo(id).then((user) => {
        setProfileLoading(false);
        setIsFollowing(user?.currentUserFollows);
        setUser(user);
      });
    } else {
      setUser(authContext.currentUser);
    }
  }, [authContext.currentUser, id]);

  const toggleFollow = () => {
    setFollowLoading(true);
    if (!isFollowing) {
      commonApi
        .followUser(id)
        .then(() => {
          setFollowLoading(false);
          setIsFollowing(true);
          if (user) {
            setUser({
              ...user,
              totalFollowers: user.totalFollowers + 1,
            });
          }
        })
        .catch(() => {
          setFollowLoading(false);
          setIsFollowing(false);
        });
    } else {
      commonApi
        .unfollowUser(id)
        .then(() => {
          setFollowLoading(false);
          setIsFollowing(false);
          if (user) {
            setUser({
              ...user,
              totalFollowers: user.totalFollowers - 1,
            });
          }
        })
        .catch(() => {
          setFollowLoading(false);
          setIsFollowing(true);
        });
    }
  };

  const onSubmitProfile = (data, isPassword) => {
    setUserModal(false);
    setPasswordModal(false);
    setProfileLoading(true);
    commonApi
      .updateUser(id, {
        ...authContext.currentUser,
        ...data,
      })
      .then(() => {
        setAuthContext({
          ...authContext,
          currentUser: {
            ...authContext.currentUser,
            ...data,
          },
        });
        setProfileLoading(false);
        setInformationModal({
          isOpen: true,
          rawBody: false,
          title: `Editar ${isPassword ? "Contraseña" : "Informacion"}`,
          body: `${
            isPassword ? "Contraseña" : "Informacion"
          } editada correctamente`,
        });
      })
      .catch((err) => {
        setProfileLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Editar Perfil",
          body: err?.response?.data?.length
            ? err?.response?.data[0]?.msg
            : "Ha habido un problema, intenta nuevamente.",
        });
      });
  };

  const onImagePreview = () => {
    setInformationModal({
      isOpen: true,
      title: "Imagen de Perfil",
      rawBody: true,
      body: (
        <img
          alt={`${user?.firstName} ${user?.lastName}`}
          src={user?.image || avatar}
          style={{ width: "100%", height: "auto" }}
        />
      ),
    });
  };

  return (
    <React.Fragment>
      <div className="mt-3 col-md-10 offset-md-1 d-flex flex-column flex-md-row px-0">
        <Col className="col-md-5 px-0 px-md-2">
          {profileLoading ? (
            <div className="min-width-50 my-2">
              <Loader size="sm" />
            </div>
          ) : (
            <>
              <Card className="text-center border rounded">
                <CardBody className="d-flex flex-column pb-1">
                  <div
                    className="rounded"
                    style={{
                      backgroundPosition: "center",
                      backgroundImage: `url(${user?.image || avatar})`,
                      width: "80px",
                      height: "80px",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      cursor: "pointer",
                      alignSelf: "center",
                    }}
                    onClick={() => onImagePreview(user?.image || avatar)}
                  ></div>
                  <h4 className="mb-0 mt-2">{`${user?.firstName} ${user?.lastName} `}</h4>
                  <div className="mt-2">
                    {followLoading ? (
                      <div className="min-width-50 my-2">
                        <Loader size="sm" />
                      </div>
                    ) : !isOwnProfile() && !isFollowing ? (
                      <Button
                        onClick={toggleFollow}
                        size="sm"
                        className="btn btn-success btn-sm mb-2"
                      >
                        Seguir
                      </Button>
                    ) : isFollowing ? (
                      <Button
                        onClick={toggleFollow}
                        size="sm"
                        className="btn btn-danger btn-sm mb-2"
                      >
                        Dejar de Seguir
                      </Button>
                    ) : null}
                  </div>
                  <div>
                    <small>
                      {user?.totalQuestions}{" "}
                      {user?.totalQuestions === 1 ? "pregunta" : "preguntas"}
                    </small>
                    <small>
                      , {user?.totalFollowers}{" "}
                      {user?.totalFollowers === 1 ? "seguidor" : "seguidores"}
                    </small>
                  </div>
                  <div className="text-center mt-3">
                    <h4 className="font-13 text-underline">Sobre mi</h4>
                    <p className="text-muted font-13 mb-3">
                      {user?.description || "-"}
                    </p>
                  </div>
                  <div className="d-flex col-12 border-top pt-2 pb-1 justify-content-center">
                    {!isOwnProfile() ? (
                      <small
                        className="text-success cursor-pointer text-underline"
                        onClick={onReport}
                      >
                        Reportar Usuario
                      </small>
                    ) : (
                      <div className="d-flex col-12 px-0 justify-content-around">
                        <small
                          className="text-success cursor-pointer text-underline"
                          onClick={onEdit}
                        >
                          Editar perfil
                        </small>
                        <small
                          className="text-success cursor-pointer text-underline"
                          onClick={onEditPassword}
                        >
                          Cambiar contraseña
                        </small>
                        <small
                          className="text-success cursor-pointer text-underline"
                          onClick={onEditPassword}
                        >
                          Darse de baja
                        </small>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
              <MyCategories />
            </>
          )}
        </Col>
        <Col className="col-md-7 px-0 px-md-2">
          <Card className="box-shadow-none">
            <CardBody className="p-0">
              <Nav
                flush="true"
                tabs
                className="cursor-pointer bg-nav-pills nav-justified border border-top border-left border-right "
              >
                <NavItem>
                  <NavLink
                    className={`${
                      activeTab === QUESTIONS_TAB
                        ? "text-white bg-secondary"
                        : ""
                    } rounded-0`}
                    onClick={() => {
                      handleTab(QUESTIONS_TAB);
                    }}
                  >
                    {isOwnProfile() ? "Mis" : null} Preguntas
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${
                      activeTab === ANSWERS_TAB ? "text-white bg-secondary" : ""
                    } rounded-0 border-left border-right`}
                    onClick={() => {
                      handleTab(ANSWERS_TAB);
                    }}
                  >
                    {isOwnProfile() ? "Mis" : null} Respuestas
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${
                      activeTab === COMMENTS_TAB
                        ? "text-white bg-secondary"
                        : ""
                    } rounded-0`}
                    onClick={() => {
                      handleTab(COMMENTS_TAB);
                    }}
                  >
                    {isOwnProfile() ? "Mis" : null} Comentarios
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={QUESTIONS_TAB}>
                  {activeTab === QUESTIONS_TAB ? (
                    <UserQuestions
                      questionsData={state[id]?.questions || {}}
                      userId={id}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId={ANSWERS_TAB}>
                  {activeTab === ANSWERS_TAB ? (
                    <UserAnswers
                      answersData={state[id]?.answers || {}}
                      userId={id}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId={COMMENTS_TAB}>
                  {activeTab === COMMENTS_TAB ? (
                    <UserComments
                      commentsData={state[id]?.comments || {}}
                      userId={id}
                    />
                  ) : null}
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
        {userModal ? (
          <UserModal
            onClose={() => setUserModal(false)}
            onSubmit={(updatedData) => onSubmitProfile(updatedData)}
          />
        ) : null}
        {passwordModal ? (
          <PasswordModal
            onClose={() => setPasswordModal(false)}
            onSubmit={(updatedData) => onSubmitProfile(updatedData, true)}
          />
        ) : null}
        {reportModal ? (
          <ReportModal
            onClose={() => setReportModal(false)}
            onSubmit={() => {
              setReportModal(false);
              history.push("/feed");
            }}
            type={REPORT_TYPE_USER}
            contentId={id}
          />
        ) : null}
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          rawBody={informationModal.rawBody}
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              rawBody: false,
            })
          }
        />
      ) : null}
    </React.Fragment>
  );
};

export default Profile;
